import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getToken } from '../../utils/getToken';
import strikeService from './strikeService';

const initialState = {
    strikes: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    result:{},
    reasons:{}
};

// Get All Strikes
export const allStrikes = createAsyncThunk('admin/strikes', async (data, thunkAPI) => {
    const token = getToken();
    try {
        if (token) {
            return await strikeService.getAllStrikes(token, data);
        }
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const createStrike = createAsyncThunk('admin/strike/add', async (data, thunkAPI) => {
    const token = getToken();
    try {
        if (token) {
            return await strikeService.createStrike(token, data);
        }
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});


export const getStrikeReasons = createAsyncThunk('admin/strike/reasons', async (data, thunkAPI) => {
    const token = getToken();
    try {
        if (token) {
            return await strikeService.getSingleWorkerStrike(token, data);
        }
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});




export const strikeSlice = createSlice({
    name: 'strikes',
    initialState,
    reducers: {
        reset: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(allStrikes.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
                state.isError = false;
            })
            .addCase(allStrikes.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.strikes = action.payload;
            })
            .addCase(allStrikes.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.strikes = null;
            }).addCase(createStrike.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
                state.isError = false;
            })
            .addCase(createStrike.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
            })
            .addCase(createStrike.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getStrikeReasons.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
                state.isError = false;
            })
            .addCase(getStrikeReasons.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.reasons = action.payload;
            })
            .addCase(getStrikeReasons.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
    },
});

export const { reset } = strikeSlice.actions; // Use strikeSlice here
export default strikeSlice.reducer;
