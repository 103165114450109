import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASEURL

// Get All Skills
const getAllSkills = async (token, data) => {
    let page = data.pageno ? data.pageno : 1;
    let url = `${BASE_URL}/admin/skills?pageno=${page}`;

    if(data.id && page){ 
        url = `${BASE_URL}/admin/skills?pageno=${page}&jobrole_id=${data.id}`
    }
    if(data.search){
        url += `&search=${data.search}`
    }

    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(url, config);
    if (response.data.body) {
        return response.data;
    }
}



// Skill Toggle Status
const toggleStatus = async (token, skill_id, pageno) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.put(`${BASE_URL}/admin/skill/status/${skill_id}?pageno=${pageno}`, null, config);
    if (response.data.body) {
        return response.data;
    }
}


const getJobRole = async (token, id) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await axios.get(`${BASE_URL}/admin/jobrole/${id}`, config);
    if (response.data) {
        return response.data;
    }
};


const addSkill = async (token, data) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(`${BASE_URL}/admin/skill`, data, config);
  return response.data; // Return the full response to handle in the thunk
};

const editSkill = async (token, data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  
    const response = await axios.put(`${BASE_URL}/admin/skill/${data.id}`, data, config);
    return response.data; // Return the full response to handle in the thunk
  };


const skillsService = {
    getAllSkills, toggleStatus ,getJobRole ,addSkill ,editSkill
}


export default skillsService;