import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASEURL

const dashboard = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(`${BASE_URL}/admin/onboarding_staff/dashboard`, config);
    if (response.data.body) {
        return response.data;
    }
}


const adminHome = async (data ,token) => {
    let url = `${BASE_URL}/admin/home`;
    let params = [];
    
    if (data?.start_date && data?.end_date) {
        params.push(`start_date=${data?.start_date}&end_date=${data?.end_date}`);
    }
    if (data?.start_date_day && data?.end_date_day) {
        params.push(`start_date_day=${data?.start_date_day}&end_date_day=${data?.end_date_day}`);
    }
    if (params.length > 0) {
        url += `?${params.join("&")}`;
    }    
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(url, config);
    if (response.data.body) {
        return response.data;
    }
}


const jobStatus = async (data , token) => {
    let url = `${BASE_URL}/admin/home/job_status?year=${data?.year}`
    if(data?.status?.length > 0){
        url = `${url}&status=${data?.status}`
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(url, config);
    if (response.data.body) {
        return response.data;
    }
}





const homeService = {
    dashboard ,adminHome ,jobStatus
}


export default homeService;