import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  reset,
  allemployerSpend,
} from "../../../features/employers/employerSlice";
import { toast } from "react-toastify";
import Nav from "../../layouts/nav";
import dateFormat from "dateformat";
import ReactPaginate from "react-paginate";
import Spinner from "../../components/Spinner";
import CustomeModal from "../../components/customeModal/CustomeModal";
import { Form, OverlayTrigger, Stack, Tooltip } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import noImage from "../../../images/noimage.png";

function EmployerSpend() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [totalCommission, setTotalCommission] = useState(0);
  const [paymentToWorker, setPaymentToWorker] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [startDatePicker, setStartDatePicker] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [singleEmployerSpend, setSingleEmployerSpend] = useState(null);
  const [checkAllCheckBox, setCheckAllCheckBox] = useState(false);
  const [search, setSearch] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const { employerSpend, isLoading, isError, message } = useSelector(
    (state) => state.employers
  );
  const total_pages = employerSpend?.total_pages;
  const employersData = employerSpend?.body;

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const handleAllCheckBox = (e) => {
    setCheckAllCheckBox(e.target.checked);

    const spends = Array.isArray(employersData)
      ? employersData?.map((data) => {
          return {
            _id: data._id,
            total_invoice_amount: Number(data.total_invoice_amount),
            total_commission_paid: Number(data.total_commission_paid),
            total_payment_to_worker: Number(data.total_payment_to_worker),
          };
        })
      : [];
    if (e.target.checked) {
      setSelectedCheckboxes(spends);
      setTotalAmount(
        spends.reduce((acc, curr) => acc + curr.total_invoice_amount, 0)
      );
      setTotalCommission(
        spends.reduce((acc, curr) => acc + curr.total_commission_paid, 0)
      );
      setPaymentToWorker(
        spends.reduce((acc, curr) => acc + curr.total_payment_to_worker, 0)
      );
    } else {
      setSelectedCheckboxes([]);
      setTotalAmount(0);
      setTotalCommission(0);
      setPaymentToWorker(0);
    }
  };
  const handleCheckBox = (item) => {
    const {
      _id,
      total_invoice_amount,
      total_commission_paid,
      total_payment_to_worker,
    } = item;

    setSelectedCheckboxes((prev) => {
      if (prev.some((checkbox) => checkbox._id === _id)) {
        // If already selected, remove it
        setTotalAmount((prevTotal) => prevTotal - total_invoice_amount);
        setTotalCommission((prevTotal) => prevTotal - total_commission_paid);
        setPaymentToWorker((prevTotal) => prevTotal - total_payment_to_worker);
        return prev.filter((checkbox) => checkbox._id !== _id);
      } else {
        // Otherwise, add it
        setTotalCommission((prevTotal) => prevTotal + total_commission_paid);
        setPaymentToWorker((prevTotal) => prevTotal + total_payment_to_worker);
        setTotalAmount((prevTotal) => prevTotal + total_invoice_amount);
        return [...prev, item];
      }
    });
  };

  const start_date = startDatePicker
    ? dateFormat(startDatePicker, "yyyy/mm/dd")
    : undefined;
  const end_date = dueDate ? dateFormat(dueDate, "yyyy/mm/dd") : undefined;

  const handleShowModal = (data) => {
    setIsModalOpen(true);
    setSingleEmployerSpend(data);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getDate = (date) => {
    return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
  };

  const data = {
    pageno: 1,
    search: search,
    from: start_date,
    to: end_date,
  };

  useEffect(() => {
    setTimeout(() => {
      if (isError && message) {
        toast.error(message);
      }
    }, 300);
  }, [isError, message]);

  useEffect(() => {
    if (!user) {
      navigate("/admin/login");
    }
    setPageNumber(0)
    dispatch(allemployerSpend(data));
    return () => dispatch(reset());
  }, [user, isError, message, search, startDatePicker && dueDate]);

  // State for pagination
  const [pageNumber, setPageNumber] = useState(0);
  const userPerPage = 10;
  const pageVisted = pageNumber;
  let pageCount;

  const getEmployer = () => {
    const formatName = (name) => {
      if (!name) return "";
      const nameParts = name.split(" ");
      if (nameParts.length > 1) {
        return `${nameParts[0][0]}.${nameParts[1]}`;
      }
      return name;
    };

    const truncateString = (text, maxLength) => {
      return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };

    pageCount = total_pages;
    return (
      Array.isArray(employersData) &&
      employersData?.map((data, index) => {
        return (
          <tr role="row" className="odd" key={index}>
            <td>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={`checkbox_${data?._id}`}
                  onChange={() =>
                    handleCheckBox({
                      _id: data?._id,
                      total_invoice_amount: Number(data?.total_invoice_amount),
                      total_commission_paid: Number(
                        data?.total_commission_paid
                      ),
                      total_payment_to_worker: Number(
                        data?.total_payment_to_worker
                      ),
                    })
                  }
                  checked={selectedCheckboxes?.some(
                    (checkbox) => checkbox._id === data._id
                  )}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`checkbox_${data?._id}`}
                />
              </div>
            </td>
            <td>
              <div className="media">
                <div className="media-body text-nowrap">
                  <h6 className=" fs-16 mb-0">
                    <img
                      src={data?.image ?? noImage}
                      alt={data?.name}
                      // onError={({ currentTarget }) => {
                      //     currentTarget.onerror = null;
                      //     currentTarget.src = noImage;
                      // }}
                      height={50}
                      width={50}
                      className="rounded-circle"
                    />
                    {/* <img src={data.image} height={50} width={50} alt="" onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = `${process.env.REACT_APP_IMAGEBASEURL}noImg.png`;
                                    }} className="rounded-circle" /> */}
                  </h6>
                </div>
              </div>
            </td>
            <td>
              <div className="media">
                <div className="media-body text-nowrap">
                  {formatName(data?.name)?.length > 10 ? (
                    <>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {formatName(data?.name)?.length > 10
                              ? formatName(data?.name)
                              : ""}
                          </Tooltip>
                        }
                      >
                        <h6
                          key={index} // Ensure unique key if rendering in a list
                          className="fs-16 mb-0 text-capitalize"
                        >
                          {truncateString(formatName(data?.name), 10)}
                        </h6>
                      </OverlayTrigger>
                    </>
                  ) : (
                    <>
                      <h6
                        key={index} // Ensure unique key if rendering in a list
                        className="fs-16 mb-0 text-capitalize"
                      >
                        {truncateString(formatName(data?.name), 10)}
                      </h6>
                    </>
                  )}
                </div>
              </div>
            </td>

            <td>
              <div className="media">
                <div className="media-body text-nowrap">
                  {data?.company?.company_name?.length > 15 ? (
                    <>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {data?.company?.company_name?.length > 15
                              ? data?.company?.company_name
                              : ""}
                          </Tooltip>
                        }
                      >
                        <h6
                          key={index} // Ensure unique key if rendering in a list
                          className="fs-16 mb-0 text-capitalize"
                        >
                          {truncateString(data?.company?.company_name, 15)}
                        </h6>
                      </OverlayTrigger>
                    </>
                  ) : (
                    <>
                      <h6
                        key={index} // Ensure unique key if rendering in a list
                        className="fs-16 mb-0 text-capitalize"
                      >
                        {truncateString(data?.company?.company_name, 15)}
                      </h6>
                    </>
                  )}
                </div>
              </div>
            </td>
            <td>
              <div className="media">
                <div className="media-body text-nowrap">
                  <h6 className=" fs-16 mb-0 mx-2">
                    £{data?.total_commission_paid}
                  </h6>
                </div>
              </div>
            </td>
            <td>
              <div className="media">
                <div className="media-body text-nowrap">
                  <h6 className=" fs-16 mb-0 mx-3">
                    £{data?.total_payment_to_worker}
                  </h6>
                </div>
              </div>
            </td>
            <td>
              <div className="media">
                <div className="media-body text-nowrap">
                  <h6 className=" fs-16 mb-0">£{data?.total_invoice_amount}</h6>
                </div>
              </div>
            </td>
            <td>
              <div className="mx-2">
                <button onClick={() => handleShowModal(data)}>
                  {/* <i className="fa fa-eye fa-2x mx-3" style={{ color: '#00B094' }} /> */}
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 38 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.5728 23.3332C20.2823 23.3332 21.668 21.8408 21.668 19.9998C21.668 18.1589 20.2823 16.6665 18.5728 16.6665C16.8633 16.6665 15.4776 18.1589 15.4776 19.9998C15.4776 21.8408 16.8633 23.3332 18.5728 23.3332Z"
                      fill="#00B094"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.32893 19.5004C4.49779 12.0639 10.9481 6.6665 18.5731 6.6665C26.1981 6.6665 32.6485 12.0639 34.8173 19.5005C34.9121 19.8256 34.9121 20.1742 34.8173 20.4993C32.6484 27.9358 26.1981 33.3332 18.5731 33.3332C10.9481 33.3332 4.49775 27.9357 2.32892 20.4992C2.23411 20.1741 2.23411 19.8255 2.32893 19.5004ZM12.3823 19.9998C12.3823 16.3179 15.1539 13.3332 18.5728 13.3332C21.9917 13.3332 24.7633 16.3179 24.7633 19.9998C24.7633 23.6817 21.9917 26.6665 18.5728 26.6665C15.1539 26.6665 12.3823 23.6817 12.3823 19.9998Z"
                      fill="#00B094"
                    />
                  </svg>
                </button>
              </div>
            </td>
          </tr>
        );
      })
    );
  };

  const changePage = async (data) => {
    dispatch(allemployerSpend({ 
      pageno: data.selected + 1 ,
      search: search,
      from: start_date,
      to: end_date,
     }));
    setPageNumber(data.selected);
  };

  const employeSpendSummary = (
    <Stack gap={3}>
      <div className="border-bottom">
        <h6 className=" mb-2 text-black">Name:</h6>
        <p className="text-capitalize mb-3 text-black">
          {singleEmployerSpend?.company?.company_name}
        </p>
      </div>

      <div className="border-bottom">
        <h6 className="mb-2 text-black">Company Logo:</h6>
        <img
          src={singleEmployerSpend?.image}
          alt=""
          width="50"
          height="50"
          className="rounded-circle mb-3"
        />
        {/* <Button
                type="button"
                className="btn-sm mb-3"
                style={{ borderRadius: "1.3rem" }}
                variant="success"
            >
                {templates?.industry?.title}
            </Button> */}
      </div>
      <div className="border-bottom">
        <h6 className="mb-2 text-black">Address:</h6>
        <p className="text-capitalize mb-3 text-black">
          {singleEmployerSpend?.company_address?.address?.line1 ||
            singleEmployerSpend?.company_address?.address}
        </p>
      </div>
      <div className="border-bottom">
        <h6 className="mb-2 text-black">Contact Name:</h6>
        <p className="text-black">
          {" "}
          {singleEmployerSpend?.company?.company_name}
        </p>
      </div>

      <div className="border-bottom">
        <h6 className="mb-2 text-black">Number:</h6>
        <p className="text-black"> {singleEmployerSpend?.company?.mobile}</p>
      </div>
      <div className="border-bottom">
        <h6 className="mb-2 text-black">Email Address:</h6>
        <p>{singleEmployerSpend?.email}</p>
      </div>
      <div className="border-bottom">
        <h6 className="mb-2 text-black">Payment to Workers:</h6>
        <p>£{singleEmployerSpend?.total_payment_to_worker}</p>
      </div>
      <div className="border-bottom">
        <h6 className="mb-2 text-black">Seleckt Fee:</h6>
        <p>£{singleEmployerSpend?.seleckt_fee}</p>
      </div>
      <div className="border-bottom">
        <h6 className="mb-2 text-black">VAT Amount:</h6>
        <p>£{singleEmployerSpend?.VAT}</p>
      </div>
      <div className="border-bottom">
        <h6 className="mb-2 text-black">NI Tax:</h6>
        <p>£{singleEmployerSpend?.NI}</p>
      </div>
      {/* <div className='border-bottom'>
                <h6 className="mb-2 text-black">APPR LEVY:</h6>
                <p>£300.00</p>
            </div> */}
      <div className="border-bottom">
        <p className="font-weight-bold mb-2 text-black">Amount:</p>
        <p>£{singleEmployerSpend?.total_invoice_amount}</p>
      </div>
    </Stack>
  );

  const handleClearFilter = () => {
    setStartDatePicker(null);
    setDueDate(null);
    setSearch(null);
  };

  return (
    <>
      {isLoading && !search ? (
        <Spinner />
      ) : (
        <span>
          <Nav />
          <div className="content-body">
            <div className="container-fluid">
              <div className="h-80">
                <div className="d-flex flex-wrap row mb-4">
                  <div className="col-lg-4">
                    <h1 className="text-black fs-35 font-w600 text-nowrap">
                      Employers Spend
                    </h1>
                    <h6 className="fs-16 mb-1">
                      {(pageVisted + userPerPage > total_pages
                        ? total_pages
                        : pageVisted + userPerPage) - pageVisted}{" "}
                      Rows per page
                    </h6>
                    {/* <span className="fs-14">Based your preferences</span> */}
                  </div>
                  <div className="col-lg-12 mb-2">
                    <Form className="d-flex flex-wrap gap-2 justify-content-end align-items-center">
                      <div
                        className="border border-dark rounded-4 d-flex align-items-center p-2 mt-4"
                        style={{
                          maxWidth: "260px",
                          height: "55px", // Fixed height of 58px
                          overflow: "hidden",
                        }}
                      >
                        <span
                          className="me-2 text-success"
                          style={{ fontSize: "0.8rem" }}
                        >
                          <i className="fas fa-search"></i>
                        </span>
                        <input
                          type="text"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder="Search name and email"
                          className="form-control border-0 bg-transparent text-black shadow-none"
                          style={{
                            color: "#6C757D",
                            height: "40px", // Adjusted height for input to fit within 58px
                          }}
                        />
                      </div>

                      <div className="date-picker-container mt-4 position-relative">
                        <ReactDatePicker
                          selected={startDatePicker}
                          onChange={(date) => setStartDatePicker(date)}
                          style={{ maxWidth: "200px" }}
                          className="form-control rounded-4 border-dark w-100 custom-dropdown-toggle bg-transparent py-4 float-end"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="From"
                          isClearable
                        />
                        <i
                          className="fa-solid fa-chevron-down fa-sm position-absolute calendar-icon-worker"
                          style={{ color: "#00B094" }}
                        />
                      </div>
                      <div className="date-picker-container mt-4 position-relative mr-3">
                        <ReactDatePicker
                          selected={dueDate}
                          onChange={(date) => setDueDate(date)}
                          style={{ maxWidth: "200px" }}
                          // className="form-control rounded-3 border-dark w-100 custom-dropdown-toggle bg-transparent py-4"
                          className="form-control rounded-4 border-dark w-100 custom-dropdown-toggle bg-transparent py-4 float-end"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="To"
                          isClearable
                        />
                        <i
                          className="fa-solid fa-chevron-down fa-sm position-absolute calendar-icon-worker"
                          style={{ color: "#00B094" }}
                        />
                      </div>

                      {/* <Form className="d-flex flex-wrap gap-2 justify-content-end"> */}
                      <div
                        className="mt-md-4 rounded-4 bg-transparent p-3 d-flex align-items-center justify-content-center border-dark"
                        style={{ border: "1px solid #F2F2F2", height: "57px" }}
                      >
                        Total Commission :{" "}
                        <span className="text-success mx-1">
                          {" "}
                          £{totalCommission}
                        </span>
                      </div>

                      <div
                        className="mt-md-4 p-3 rounded-4 bg-transparent  d-flex align-items-center justify-content-center border-dark"
                        style={{ border: "1px solid #F2F2F2", height: "57px" }}
                      >
                        Total Payment to worker :{" "}
                        <span className="text-success mx-1">
                          {" "}
                          £{paymentToWorker}
                        </span>
                      </div>
                      <div
                        className="mt-md-4  p-3 rounded-4 bg-transparent d-flex align-items-center justify-content-center border-dark"
                        style={{ border: "1px solid #F2F2F2", height: "57px" }}
                      >
                        Total Amount :{" "}
                        <span className="text-success mx-1">
                          {" "}
                          £ {totalAmount}{" "}
                        </span>
                      </div>
                    </Form>
                    <Link
                      to="#"
                      className="float-end text-success text-right mx-4 text-decoration-underline mt-3"
                      onClick={handleClearFilter}
                      style={{ cursor: "pointer" }}
                    >
                      Clear All
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12">
                    <div className="table-responsive">
                      <div
                        id="example5_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <table
                          className="table display mb-4 dataTablesCard card-table dataTable no-footer"
                          id="example5"
                          role="grid"
                          aria-describedby="example5_info"
                        >
                          <thead>
                            <tr role="row">
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="ID: activate to sort column ascending"
                              >
                                <div className="custom-control custom-checkbox my-0 py-0 text-white">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="checkAll"
                                    onChange={handleAllCheckBox}
                                    checked={checkAllCheckBox}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="checkAll"
                                  />
                                </div>
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Company: activate to sort column ascending"
                              >
                                Logo
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Company: activate to sort column ascending"
                              >
                                Name
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Status: activate to sort column ascending"
                              >
                                Company Name
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Contact: activate to sort column ascending"
                                style={{ width: 200 }}
                              >
                                Commission
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Contact: activate to sort column ascending"
                                style={{ width: 200 }}
                              >
                                Worker Payment
                              </th>

                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Contact: activate to sort column ascending"
                                // style={{width: 150}}
                              >
                                Amount
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Contact: activate to sort column ascending"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>{getEmployer()}</tbody>
                        </table>
                        <div className="d-flex align-items-center justify-content-between pb-2">
                          <div
                            className="dataTables_info"
                            id="example5_info"
                            role="status"
                            aria-live="polite"
                          >
                            Showing {pageVisted + 1}
                            of {total_pages} pages
                          </div>
                          <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="example5_paginate"
                          >
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"paginationBttns"}
                              previousLinkClassName={
                                "paginate_button previous previousBttn"
                              }
                              nextLinkClassName={
                                "paginate_button next nextBttn"
                              }
                              pageLinkClassName={"paginate_button mr-1 ml-1"}
                              disabledClassName={"paginationDisabled"}
                              activeClassName={"paginationActive"}
                              forcePage={pageNumber}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>
      )}
      <CustomeModal
        title="Employer Spend"
        show={isModalOpen}
        onHide={handleCloseModal}
        content={employerSpend ? employeSpendSummary : "Loading..."}
      />
    </>
  );
}

export default EmployerSpend;
