import React from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";

const DualLine3 = ({chartData}) => {  
  const data = {
    defaultFontFamily: "Poppins",
    labels: [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December",
    ],
    datasets: [
      {
        label: "Hired",
        data: chartData?.find((item) => item.type === 'hired')?.month,   
        borderColor: "#00b094",
        borderWidth: 5,
        pointHoverRadius: 10,
        backgroundColor: "transparent",
        pointBackgroundColor: "#00b094",
      },
      {
        label: "Posted",
        data: chartData?.find((item) => item.type === 'posted')?.month,
        borderColor: "#67088a",
        borderWidth: 5,
        pointHoverRadius: 10,
        backgroundColor: "transparent",
        pointBackgroundColor: "#67088a",
      },
      {
        label: "Ongoing",
        data: chartData?.find((item) => item.type === 'ongoing')?.month,
        borderColor: "#817828",
        borderWidth: 5,
        pointHoverRadius: 10,
        backgroundColor: "transparent",
        pointBackgroundColor: "#817828",
      },
      {
        label: "Completed",
        data: chartData?.find((item) => item.type === 'completed')?.month,
        borderColor: "#ffe711",
        borderWidth: 5,
        pointHoverRadius: 10,
        backgroundColor: "transparent",
        pointBackgroundColor: "#ffe711",
      },
      {
        label: "Cancelled by Worker",
        data: chartData?.find((item) => item.type === 'cancelled_by_worker')?.month,
        borderColor: "#ff0000",
        borderWidth: 5,
        pointHoverRadius: 10,
        backgroundColor: "transparent",
        pointBackgroundColor: "#ff0000",
      },
      {
        label: "Cancelled by Employer",
        data: chartData?.find((item) => item.type === 'cancelled_by_employer')?.month,
        borderColor: "#000000",
        borderWidth: 5,
        pointHoverRadius: 10,
        backgroundColor: "transparent",
        pointBackgroundColor: "#000000",
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        intersect: false,
      },
    },
    hover: {
      intersect: true,
    },
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          max: 100,
          min: 0,
          stepSize: 20,
          padding: 10,
        },
      },
      x: {
        ticks: {
          padding: 5,
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  return <Line data={data} options={options} style={{ width: "100%" }} />;
};

export default DualLine3;
