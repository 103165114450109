
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button, Modal } from 'react-bootstrap';
import { Accordion } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { allFaqs, createfaq, editfaq, reset } from "../../../features/faq/faqSlice";
import { toast } from "react-toastify";
import Nav from "../../layouts/nav";
import ReactPaginate from "react-paginate";
import Spinner from "../../components/Spinner";

function FAQS() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [fmessage, setFMessage] = useState("");
  const [modalName, setModalName] = useState(""); 
  const [faqId, setFaqId] = useState("");

  const handleClose = () => setShow(false);

  const { faqs, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.faqs
  );

  const handleShow = (modal_name , data) =>{
    if(modal_name === "add"){
      setModalName("add")
      setTitle("")
      setFMessage("")
    }else if (modal_name === "edit"){
      setModalName("edit")
      setFaqId(data._id)
      setTitle(data.title)
      setFMessage(data.message)
    }
    setShow(true);
  } 


  const onSubmit = (e) => {
    e.preventDefault();
    if(modalName === "add"){
      console.log("add condition")
    dispatch(createfaq({ title, fmessage, pageno: pageNumber === 0 ? 1 : pageNumber }));
    setShow(false);
    toast.success(message)}
    else if(modalName === "edit"){
      console.log("edit condition")
      dispatch(editfaq({ title, fmessage, pageno: pageNumber === 0 ? 1 : pageNumber ,faq_id:faqId }));
      setShow(false);
      toast.success(message)
    }
  };

  useEffect(() => {
    if (isError) {
      // toast.error(message);
    }
    if (isSuccess) {
      // toast.success(message);
    }
    if (!user) {
      navigate("/admin/login");
    }
    dispatch(allFaqs(1));
    return () => reset();
  }, [user, isError ,isSuccess]);
  const [pageNumber, setPageNumber] = useState(0);
  const userPerPage = 10;
  const pageVisted = pageNumber;
  // let workers;
  let pageCount;
  const total_pages = faqs?.total_pages

  const FAQSData = Array.isArray(faqs?.body) ? faqs?.body : [];
  const getFAQS = () => {
    pageCount = total_pages;
    return FAQSData.length > 0 ? FAQSData?.map((data, index) => {
      return (
        <Accordion.Item eventKey={index} key={index}>
          <div style={{display:"flex",justifyContent:"space-between"}}>
        <div>
          <Accordion.Header className="p-2"><b>{data.title}</b></Accordion.Header>
          <Accordion.Body className="px-5 py-3">{data.message}</Accordion.Body>
          </div>
          <div style={{margin:"10px"}}>
          <button onClick={()=>handleShow("edit" ,data)}>
                    <svg
                      width="27"
                      height="27"
                      viewBox="0 0 31 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.3307 2.66665H6.9974C5.13055 2.66665 4.19713 2.66665 3.48409 3.02996C2.85689 3.34954 2.34695 3.85948 2.02737 4.48668C1.66406 5.19972 1.66406 6.13314 1.66406 7.99998V24C1.66406 25.8668 1.66406 26.8002 2.02737 27.5133C2.34695 28.1405 2.85689 28.6504 3.48409 28.97C4.19713 29.3333 5.13055 29.3333 6.9974 29.3333H22.9974C24.8642 29.3333 25.7977 29.3333 26.5107 28.97C27.1379 28.6504 27.6478 28.1405 27.9674 27.5133C28.3307 26.8002 28.3307 25.8668 28.3307 24V16.8333M20.8307 5.16665L25.5448 9.8807M12.9353 13.0621L24.0157 1.98168C25.3174 0.679935 27.428 0.679933 28.7297 1.98168C30.0315 3.28343 30.0315 5.39398 28.7297 6.69573L17.2931 18.1323C16.0237 19.4017 15.389 20.0365 14.6661 20.5412C14.0244 20.9892 13.3323 21.3604 12.604 21.647C11.7836 21.97 10.9037 22.1475 9.14394 22.5026L8.33073 22.6667L8.40982 22.1131C8.68967 20.1541 8.82959 19.1746 9.14788 18.2602C9.43031 17.4487 9.81614 16.6771 10.2958 15.9643C10.8364 15.161 11.536 14.4613 12.9353 13.0621Z"
                        stroke="#00B094"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
        </button>
        </div>
        </div>
        </Accordion.Item>
      );
    }) : <p style={{textAlign:"center"}}>No FAQs available</p>;
  };


  const changePage = async (data) => {
    setPageNumber(data.selected);
    dispatch(allFaqs(data.selected + 1))
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <span>
          <Nav />
          <div className="content-body">
            <div className="container-fluid">
              <div className="h-80">
                <div className="d-flex flex-wrap mb-4 row">
                  <div className="col-xl-3 col-lg-4 mb-2">
                    <h1 className="text-black fs-35 font-w600 mb-3">
                      FAQ's
                    </h1>
                    <h6 className="fs-16 mb-1">
                      {userPerPage} Rows per page
                    </h6>
                    {/* <span className="fs-14">Based your preferences</span> */}
                  </div>
                  <div className="col-3 offset-6"> <button className="btn btn-rounded btn-md btn-success float-right" onClick={()=>handleShow("add")}>Add FAQ</button></div>
                </div>
                <div className="row">
                  <div className="col-xl-12">
                    <div className="table-responsive">
                      <div
                        id="example5_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <div className="card">
                          <Accordion defaultActiveKey="0" flush>
                            {getFAQS()}
                          </Accordion>
                        </div>
                        <div className="d-flex align-items-center justify-content-between pb-2">
                          <div
                            className="dataTables_info"
                            id="example5_info"
                            role="status"
                            aria-live="polite"
                          >
                            Showing {pageVisted + 1}
                            of {total_pages} pages
                          </div>
                          <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="example5_paginate"
                          >
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"paginationBttns"}
                              previousLinkClassName={
                                "paginate_button previous previousBttn"
                              }
                              nextLinkClassName={
                                "paginate_button next nextBttn"
                              }
                              pageLinkClassName={"paginate_button mr-1 ml-1"}
                              disabledClassName={"paginationDisabled"}
                              activeClassName={"paginationActive"}
                              forcePage={pageNumber}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title style={{textTransform: "capitalize"}}> {modalName} FAQ</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form className="form-group" onSubmit={onSubmit}>
                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="">Message</label>
                      <textarea required className="form-control" onChange={(e) => setFMessage(e.target.value)} value={fmessage} autoFocus cols="10" rows="5"></textarea>
                    </div>
                    <div className="col-12">
                      <label htmlFor="">Title</label>
                      <input type="text" className="form-control" onChange={(e) => setTitle(e.target.value)} value={title} />
                    </div>
                  </div>
                  <Modal.Footer>
                    <Button type="submit" variant="success" className="btn-block rounded-4">
                      Save Changes
                    </Button>
                    <Button variant="primary" className="btn-block rounded-4" onClick={handleClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </form>
              </Modal.Body>
            </Modal>
          </div>
        </span>
      )}
    </>
  );
}

export default FAQS;
