import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASEURL

// get All Expenses
const getExpenses = async (token, data) => {
    let page = data.pageno ? data.pageno : 1;
    let url = `${BASE_URL}/admin/expenses?pageno=${page}`;

    if(data.search){
        url += `&search=${data.search}`
    }

    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(url, config);
    if (response.data.body) {
        return response.data;
    }
}



// create Uniform
const createExpense = async (token, data) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.post(`${BASE_URL}/admin/expense`, data, config);
    if (response.data.body) {
        return response.data;
    }

}


const editExpense = async (token, data) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.put(`${BASE_URL}/admin/expense/${data._id}`, data, config);
    if (response.data.body) {
        return response.data;
    }

}
const expenseService = {
    getExpenses, createExpense ,editExpense
}


export default expenseService;