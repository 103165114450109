import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { allworkers, reset } from "../../../features/workers/workerSlice";
import Nav from "../../layouts/nav";
import dateFormat from "dateformat";
import ReactPaginate from "react-paginate";
import Spinner from "../../components/Spinner";
import {
  Button,
  Dropdown,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import noImage from "../../../images/noimage.png";
import { toast } from "react-toastify";
import { createStrike } from "../../../features/strikes/strikeSlice";

function StrikesWorker() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [strikeNumber, setStrikeNumber] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [modalAddStrikes, setModalAddStrikes] = useState(false);
  const [workerId, setWorkerId] = useState(null);
  const [strikeReason, setStrikeReason] = useState(''); 



  const handleSelectOption = (key, event) => {
    const ariaLabel = event.target.getAttribute("aria-label");

    switch (ariaLabel) {
      case "strike_number":
        setStrikeNumber(key);
        break;
      default:
        break;
    }
  };

  const handleShowModal = (id) => {
    setWorkerId(id)
    setModalAddStrikes(true)
  }

  const { user } = useSelector((state) => state.auth);
  const { workers, isLoading, isError, message } = useSelector(
    (state) => state.workers
  );

  const getDate = (date) => {
    return dateFormat(date, "yyyy-mm-dd ");
  };


  const handleSubmit = (e) => {
      e.preventDefault(); 
      dispatch(createStrike({ worker_id: workerId, strike_reason: strikeReason }))
      .then((result) => {
          if (result.meta.requestStatus === 'fulfilled') {
              dispatch(allworkers({ pageno : pageNumber }))
              setModalAddStrikes(false)
          } else {
              console.error('Failed to create strike:', result.error.message);
          }
      });
  };


  // useEffect(() => {
  //   if (isError) {
  //     // toast.error(message);
  //   }
  //   if (!user) {
  //     navigate("/admin/login");
  //   }
  //   dispatch(allworkers(1));
  //   return () => reset();
  // }, [user, navigate, isError, message, dispatch]);

  useEffect(() => {
    setTimeout(() => {
      if (isError) {
        toast.error(message);
      }
      if (!user) {
        navigate("/login");
      }
    }, 500);

    return () => dispatch(reset());
  }, [user, navigate, isError, message, dispatch]);

  const data = {
    pageno: 1,
    search : name?.length > 2 ? name : null,  
    strike: strikeNumber,
  };

  useEffect(() => {
    dispatch(allworkers(data));
  }, [name?.length > 2 ,strikeNumber]);

  const userPerPage = 10;
  const pageVisted = pageNumber;

  let pageCount;
  const total_pages = workers?.total_pages;
  const workersData = workers?.body;

  const truncateString = (text, maxLength) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  const getWorker = () => {
    pageCount = total_pages;

    return (
      Array.isArray(workersData) &&
      workersData?.map((data, index) => {
        return (
          <tr role="row" className="odd" key={index}>
            <td>{index + 1 + (pageNumber === 0 ? 0 : pageNumber * 10)}.</td>
            <td>
              <div className="media">
                <div className="media-body text-nowrap gap-2">
                  <div className="">
                    <Link to={"/admin/worker/Profile/" + data._id}>
                      <div className="media-body text-nowrap btn p-0">
                        <img
                          src={data?.image}
                          alt={data?.name}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = noImage;
                          }}
                          height={50}
                          width={50}
                          className="rounded-circle"
                        />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div className="media">
                <div className="media-body text-nowrap">
                  {
                  data?.name?.length > 20 ? 
                    (
                    <>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        {data?.name?.length > 20 ? data?.name : ""}
                      </Tooltip>
                    }
                  >
                    <h6
                      key={index} // Ensure unique key if rendering in a list
                      className="fs-16 mb-0 text-capitalize"
                    >
                      {truncateString(data?.name, 20)}
                    </h6>
                   </OverlayTrigger>
                    </>
                    ):
                    (
                    <>
                     <h6
                      key={index} // Ensure unique key if rendering in a list
                      className="fs-16 mb-0 text-capitalize"
                    >
                      {data?.name}
                    </h6>
                    </>
                    )
                  }
                
                </div>
              </div>
            </td>

            <td>
              <div className="media">
                <div className="media-body text-nowrap">
                  <h6 className=" fs-16 mb-0">{getDate(data?.createdAt)}</h6>
                </div>
              </div>
            </td>

            <td>
              <div className="media">
                <div className="media-body text-nowrap">
                  <h6 className=" fs-16 mb-0">{data?.strikes || 0}</h6>
                </div>
              </div>
            </td>
            <td>
              <div className="mx-2">
                <div>
                  <Button
                    variant="primary"
                    className="btn btn-rounded btn-sm"
                    // onClick={() => setModalAddStrikes(true)}
                    onClick={()=>handleShowModal(data?._id)}
                    >
                    + Add Strike
                  </Button>
                </div>
              </div>
            </td>
          </tr>
        );
      })
    );
  };

  const changePage = async (data) => {
    const newData = {
      pageno: data.selected + 1,
    };
    dispatch(allworkers(newData));
    setPageNumber(data.selected + 1);
  };

  const dropdownOptions = [
    { key: 1, label: "1" },
    { key: 2, label: "2" },
    { key: 3, label: "3" },
    { key: 4, label: "4" },
    { key: 5, label: "5" },
  ];

  const handleClearFilters = () => {
    setStrikeNumber(null);
    setName("");
  };

  return (
    <>
      {isLoading && !name ? (
        <Spinner />
      ) : (
        <span>
          <Nav />
          <div className="content-body">
            <div className="container-fluid">
              <div className="h-80">
                <div className="row mb-4">
                  <div className="col-lg-2 mb-2">
                    <h1 className="text-black fs-35 font-w600">Workers</h1>
                    <h6 className="fs-16 mb-1">
                      {(pageVisted + userPerPage > total_pages
                        ? total_pages
                        : pageVisted + userPerPage) - pageVisted}
                      Rows per page
                    </h6>
                  </div>
                  <div className="col-lg-10 col-sm-12 mb-2">
                    <Form className="d-flex flex-wrap gap-2 w-100 justify-content-end">
                      <div className="date-picker-container position-relative">
                        <div style={{ maxWidth: "260px" }}>
                          <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder={"Search by name"}
                            className="form-control py-3 rounded-4 border-dark bg-transparent text-black"
                            style={{
                              color: "#6C757D",
                            }}
                          />
                        </div>
                      </div>
                      <Dropdown
                        onSelect={(key) =>
                          handleSelectOption(key, {
                            target: { getAttribute: () => "strike_number" },
                          })
                        }
                        className="flex-grow-1"
                        style={{ maxWidth: "260px" }}
                      >
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="py-3 rounded-4 border-dark  w-100 custom-dropdown-toggle bg-transparent text-success"
                        >
                          <span className="mx-1" style={{ color: "#6C757D" }}>
                            {strikeNumber
                              ? strikeNumber
                              : "Select Strike Number"}
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {dropdownOptions.map((option) => (
                            <Dropdown.Item
                              key={option.key}
                              eventKey={option.key}
                            >
                              {option.label}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Form>
                    <Link
                      to="#"
                      className="float-end text-success text-right mx-4 text-decoration-underline"
                      onClick={() => handleClearFilters(null)}
                    >
                      Clear All
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12">
                    <div className="table-responsive">
                      <div
                        id="example5_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <table
                          className="table display mb-4 dataTablesCard card-table dataTable no-footer"
                          id="example5"
                          role="grid"
                          aria-describedby="example5_info"
                        >
                          <thead className="bg-primary">
                            <tr role="row">
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="ID: activate to sort column ascending"
                              >
                                Id
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Company: activate to sort column ascending"
                              >
                                Picture
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Company: activate to sort column ascending"
                              >
                                Name
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Date Applied: activate to sort column ascending"
                              >
                                Joined Date
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Date Applied: activate to sort column ascending"
                              >
                                Strikes
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Status: activate to sort column ascending"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>{getWorker()}</tbody>
                        </table>
                        <div className="d-flex align-items-center justify-content-between pb-2">
                          <div
                            className="dataTables_info"
                            id="example5_info"
                            role="status"
                            aria-live="polite"
                          >
                            Showing {pageVisted + 1}
                            of {total_pages} pages
                          </div>
                          <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="example5_paginate"
                          >
                            <ReactPaginate
                              previousLabel={"Previous"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"paginationBttns"}
                              previousLinkClassName={
                                "paginate_button previous previousBttn"
                              }
                              nextLinkClassName={
                                "paginate_button next nextBttn"
                              }
                              pageLinkClassName={"paginate_button mr-1 ml-1"}
                              disabledClassName={"paginationDisabled"}
                              activeClassName={"paginationActive"}
                              forcePage={pageNumber}
                            />
                          </div>
                        </div>

                        {/* <App 
                        totalPages={pageCount}
                        currentPage={pageVisted + 1}
                        onPageChange={changePage}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>
      )}
            <Modal className="fade mt-5" show={modalAddStrikes}>
                <Modal.Header>
                    <Modal.Title>Add Strike</Modal.Title>
                    <Button
                        variant=""
                        className="close"
                        onClick={() => setModalAddStrikes(false)}
                    >
                        <span>&times;</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
               <form onSubmit={handleSubmit}>
                <div>
                    <div
                        className="textarea-container rounded-3 my-4"
                        style={{ position: 'relative', border: '1px solid #e5e5e5' }}
                    >
                        <textarea
                            className="form-control rounded-3 border-0"
                            placeholder="Enter strike reason"
                            rows={5}
                            autoFocus
                            required
                            style={{ paddingRight: '40px' }} // Adjust padding to accommodate icon
                            value={strikeReason} // Bind the state to the input value
                            onChange={(e) => setStrikeReason(e.target.value)} // Update state on input change
                        />
                    </div>
                </div>
                <div className="d-flex flex-column">
                    <Button type="submit" variant="success" className="rounded-4 my-3">
                        Add Strike
                    </Button>
                </div>
            </form>
        </Modal.Body>
                {/* <Modal.Footer>
                        <Button
                           variant="danger light"
                           onClick={() => setModalWithTooltip(false)}
                        >
                           Close
                        </Button>
                        <Button variant="primary">Save changes</Button>
                     </Modal.Footer> */}
            </Modal>
    </>
  );
}

export default StrikesWorker;
