import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {  reset } from "../../../features/templates/templateSlice";
import { toast } from "react-toastify";
import Nav from "../../layouts/nav";
import dateFormat from "dateformat";
import ReactPaginate from "react-paginate";
import Spinner from "../../components/Spinner";
import CustomeModal from "../../components/customeModal/CustomeModal";
import {Form, OverlayTrigger, Stack, Tooltip } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { allInvoices, singleInvoice } from "../../../features/invoices/invoiceSlice";
import InvoiceForm from "./invoiceEditForm";

function Invoices() {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const [startDatePicker, setStartDatePicker] = useState(null); // Set initial value to null
   const [dueDate, setDueDate] = useState(null); // Set initial value to 
   const [mode, setMode] = useState(null);
   const [pageNumber, setPageNumber] = useState(0);
   // const [singleInvoice, setSingleInvoice] = useState(null);   
   // console.log(singleInvoice, 'singleInvoice')
   const { user } = useSelector((state) => state.auth);
   // const { templates, isLoading, isError, message } = useSelector(
   //    (state) => state.templates
   // );
   const { invoices ,isLoading ,isError , message ,invoice_detail } = useSelector(
      (state) => state.invoices
   );
   const  single_invoice = invoice_detail?.body || {}
  
   const getDate = (date) => {
      return dateFormat(date, "yyyy-mm-dd");
   };

   const pending_date = startDatePicker ? getDate(startDatePicker) : null
   const due_date = dueDate ? getDate(dueDate) : null

   const [isModalOpen, setIsModalOpen] = useState(false)
   const [showPaymentDetails, setShowPaymentDetails] = useState(false)
   const [checkAllCheckBox, setCheckAllCheckBox] = useState(false)
   const [search, setSearch] = useState(null)


   const handleAllCheckBox = (e) => {
      setCheckAllCheckBox(e.target.checked)
     
     const invoices = 
      Array.isArray(invoicesData) ?  
      invoicesData?.map((data) => {
          return {
              _id: data._id,
              amount_due: parseInt(data.amount_due),
          };
      }) : [];
      if(e.target.checked){
         setSelectedCheckboxes(invoices)
         setTotalAmount(invoices.reduce((acc, curr) => acc + curr.amount_due, 0))
      }else{
         setSelectedCheckboxes([])
         setTotalAmount(0)
      }

   }

   const [selectedCheckboxes, setSelectedCheckboxes] = useState([]); // Track selected objects
   const [totalAmount, setTotalAmount] = useState(0); // Track the total amount

   const handleCheckBox = (item) => {
       const { _id, amount_due } = item;

       setSelectedCheckboxes((prev) => {
           if (prev.some((checkbox) => checkbox._id === _id)) {
               // If already selected, remove it
               setTotalAmount((prevTotal) => prevTotal - amount_due);
               return prev.filter((checkbox) => checkbox._id !== _id);
           } else {
               // Otherwise, add it
               setTotalAmount((prevTotal) => prevTotal + amount_due);
               return [...prev, item];
           }
       });
   };


   const handleShowModal = (modal_name , data) => {
      setIsModalOpen(true)
      dispatch(singleInvoice(data._id))
      setMode(modal_name)
   }
   const handleCloseModal = () => {
      setIsModalOpen(false)
      dispatch(allInvoices({pageno:pageNumber}))
   }

   const handleShowMoreClick = () => {
      setShowPaymentDetails(!showPaymentDetails);
   };

   const queryData = {
      pageno: 1,
      pending_date: pending_date,
      due_date: due_date ,
      search: search
   }
   useEffect(() => {
      if (isError && message) {
         toast.error(message);
      }
      if (!user) {
         navigate("/admin/login");
      }
    },[isError, message, user]);
   useEffect(() => {
      setPageNumber(0);
      dispatch(allInvoices(queryData));
      return () => dispatch(reset());
   }, [user,pending_date ,due_date ,search]);
  
   const userPerPage = 10;
   const pageVisted = pageNumber;
   let pageCount;
   const total_pages = invoices?.total_pages
   const invoicesData = invoices?.body?.invoices
   const getInvoices = () => {

      const formatName = (name) => {
         if (!name) return '';
         const nameParts = name.split(' ');
         if (nameParts.length > 1) {
            return `${nameParts[0][0]}.${nameParts[1]}`;
         }
         return name;
      };

      const truncateString = (text, maxLength) => {
         return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
      };

      pageCount = total_pages;
      return Array.isArray(invoicesData) && invoicesData?.map((data, index) => {

         return (
            <tr role="row" className="odd" key={index}>
               <td>
                  <div className="form-group">
                     {/* <div className="custom-control custom-checkbox ml-1 text-white mt-2">
                     <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`checkbox_${data._id}`}
                        checked={selectedCheckboxes.includes(data._id)} // Controlled checkbox
                        onChange={() => handleCheckBox(data._id)}
                    />
                        <label
                           className="custom-control-label"
                           // htmlFor={`checkbox_${data._id}`}
                        >
                        </label>
                     </div> */}

                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input"     
                        id={`checkbox_${data?._id}`}
                        onChange={() => handleCheckBox({_id:data?._id ,amount_due: parseInt(data?.amount_due)})}
                        checked={selectedCheckboxes.some(
                           (checkbox) => checkbox._id === data._id
                       )}
                     />
                     <label className="custom-control-label" htmlFor={`checkbox_${data?._id}`} />
                    </div>
                  </div>
               </td>
               <td>{index + 1 + (pageNumber === 0 ? 0 : pageNumber * 10)}.</td>
               <td>
                  <div className="media">
                     <div className="media-body text-nowrap">
                        <h6 className="fs-16 mb-0">
                           <img src={data?.employer?.image} alt="" className="rounded-circle" width="50" height="50" />
                        </h6>
                     </div>
                  </div>
               </td>

               <td>
                  <div className="media">
                     <div className="media-body text-nowrap">
                        <h6
                           className="fs-16 mb-0 text-capitalize"
                           title={data?.employer?.name?.length > 20 ? data?.employer?.name : ''}
                        >
                           {truncateString(formatName(data?.employer?.name), 20)}
                        </h6>
                     </div>
                  </div>
               </td>
               <td>
                  <div className="media">
                     <div className="media-body text-nowrap">
                        <h6 className="fs-16 mb-0">
                           {data.company.company_name}
                        </h6>
                     </div>
                  </div>
               </td>
               <td>
                  <div className="media">
                     <div className="media-body text-nowrap">
                        <h6 className="fs-16 mb-0 mx-3">
                           {data.invoice_number}
                        </h6>
                     </div>
                  </div>
               </td>
               {/* <td>
                  <div className="media">
                     <div className="media-body text-nowrap">
                        <div className="fs-16 mb-0">
                           {(() => {
                              if (data.status === "0") {
                                 return (
                                    <button onClick={() => {
                                       dispatch(toggleStatus({ template_id: data._id, pageno: pageNumber === 0 ? 1 : pageNumber }));
                                    }} className='btn btn-rounded btn-success'>Pending</button>
                                 )
                              } else if (data.status === "1") {
                                 return (
                                    <button onClick={() => {
                                       dispatch(toggleStatus({ template_id: data._id, pageno: pageNumber === 0 ? 1 : pageNumber }));
                                    }} className='btn btn-rounded btn-success'>Approved</button>
                                 )
                              } else {
                                 return (
                                    <button onClick={() => {
                                       dispatch(toggleStatus({ template_id: data._id, pageno: pageNumber === 0 ? 1 : pageNumber }));
                                    }} className='btn btn-rounded btn-danger'>Declined</button>
                                 )
                              }
                           })()}
                        </div>
                     </div>
                  </div>
               </td> */}

               <td>
                  <div className="media">
                     <div className="media-body text-nowrap">
                        <h6 className="fs-16 mb-0">
                           {getDate(data?.date)}
                        </h6>
                     </div>
                  </div>
               </td>
               <td>
                  <div className="media">
                     <div className="media-body text-nowrap">
                        <h6 className="fs-16 mb-0">

                           { data.due_date ? getDate(data?.due_date) : "Not Added yet"}
                        </h6>
                     </div>
                  </div>
               </td>
               <td>
                  <div className="media">
                     <div className="media-body text-nowrap">
                          {data?.company_address?.line1?.length > 20 ? (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                       <Tooltip>
                                       {data?.company_address?.line1} {/* Tooltip text */}
                                      </Tooltip>
                                    }
                                    >
                                 <h6
                                    key={index} // Ensure unique key if rendering in a list
                                    className="fs-16 mb-0 text-nowrap"
                                   >
                                   {truncateString(data?.company_address?.line1, 20)}
                                  </h6>
                                </OverlayTrigger>
                                   ) : (
                                    <h6 key={index} className="fs-16 mb-0 text-nowrap">
                                        {data?.company_address?.line1}
                                    </h6>
                                  )}
                     </div>
                  </div>
               </td>
               <td>
                  <div className="mx-2 d-flex gap-10px">
                     <button onClick={() => handleShowModal("summary", data)}>
                        {/* <i className="fa fa-eye fa-2x mx-3" style={{ color: '#00B094' }} /> */}
                        <svg
                           width="30"
                           height="30"
                           viewBox="0 0 38 40"
                           fill="none"
                           xmlns="http://www.w3.org/2000/svg"
                        >
                           <path
                              d="M18.5728 23.3332C20.2823 23.3332 21.668 21.8408 21.668 19.9998C21.668 18.1589 20.2823 16.6665 18.5728 16.6665C16.8633 16.6665 15.4776 18.1589 15.4776 19.9998C15.4776 21.8408 16.8633 23.3332 18.5728 23.3332Z"
                              fill="#00B094"
                           />
                           <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M2.32893 19.5004C4.49779 12.0639 10.9481 6.6665 18.5731 6.6665C26.1981 6.6665 32.6485 12.0639 34.8173 19.5005C34.9121 19.8256 34.9121 20.1742 34.8173 20.4993C32.6484 27.9358 26.1981 33.3332 18.5731 33.3332C10.9481 33.3332 4.49775 27.9357 2.32892 20.4992C2.23411 20.1741 2.23411 19.8255 2.32893 19.5004ZM12.3823 19.9998C12.3823 16.3179 15.1539 13.3332 18.5728 13.3332C21.9917 13.3332 24.7633 16.3179 24.7633 19.9998C24.7633 23.6817 21.9917 26.6665 18.5728 26.6665C15.1539 26.6665 12.3823 23.6817 12.3823 19.9998Z"
                              fill="#00B094"
                           />
                        </svg>

                     </button>
                  <div className="fs-16 mb-0">
                   <button onClick={() => handleShowModal("edit" ,data)}>
                    <svg
                      width="27"
                      height="27"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.3307 6.66665H11.9974C10.1306 6.66665 9.19713 6.66665 8.48409 7.02996C7.85689 7.34954 7.34695 7.85948 7.02737 8.48668C6.66406 9.19972 6.66406 10.1331 6.66406 12V28C6.66406 29.8668 6.66406 30.8002 7.02737 31.5133C7.34695 32.1405 7.85689 32.6504 8.48409 32.97C9.19713 33.3333 10.1306 33.3333 11.9974 33.3333H27.9974C29.8642 33.3333 30.7977 33.3333 31.5107 32.97C32.1379 32.6504 32.6478 32.1405 32.9674 31.5133C33.3307 30.8002 33.3307 29.8668 33.3307 28V20.8333M25.8307 9.16665L30.5448 13.8807M17.9353 17.0621L29.0157 5.98168C30.3174 4.67994 32.428 4.67993 33.7297 5.98168C35.0315 7.28343 35.0315 9.39398 33.7297 10.6957L22.2931 22.1323C21.0237 23.4017 20.389 24.0365 19.6661 24.5412C19.0244 24.9892 18.3323 25.3604 17.604 25.647C16.7836 25.97 15.9037 26.1475 14.1439 26.5026L13.3307 26.6667L13.4098 26.1131C13.6897 24.1541 13.8296 23.1746 14.1479 22.2602C14.4303 21.4487 14.8161 20.6771 15.2958 19.9643C15.8364 19.161 16.536 18.4613 17.9353 17.0621Z"
                        stroke="#00B094"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
               </div>
            </div>
         </td>
      </tr>);
      });
   };

   const changePage = async (data) => {
      dispatch(allInvoices({ 
         pageno: data.selected + 1 ,    
         pending_date: pending_date,
         due_date: due_date ,
         search: search
       }));
      setPageNumber(data.selected);
   };


   const invoiceSummary = (
      <Stack gap={3}>
         <div className='border-bottom'>
            <h6 className="mb-2 text-black">Name:</h6>
            <p className="text-capitalize mb-3 text-black">{single_invoice?.employer?.name}</p>
         </div>
         <div className='border-bottom'>
            <h6 className="font-weight-bold mb-2 text-black">ID:</h6>
            <p className="text-capitalize mb-3 text-black">{single_invoice?._id}</p>
         </div>

         <div className='border-bottom'>
            <h6 className="font-weight-bold mb-2 text-black">Company Name:</h6>
            <p className="text-capitalize mb-3 text-black">{single_invoice?.company?.company_name}</p>
         </div>

         <div className='border-bottom'>
            <h6 className="font-weight-bold mb-1 text-black">Logo:</h6>
            <img src={single_invoice?.employer?.image} alt="" width="50" className="rounded-circle mb-3" height="50" />
         </div>

         <div className='border-bottom'>
            <h6 className="font-weight-bold mb-2 text-black">Address:</h6>
            <p className="text-capitalize mb-3 text-black">{single_invoice?.company_address?.line1}</p>
         </div>

         <div className='border-bottom'>
            <h6 className="font-weight-bold mb-2 text-black">Email Address:</h6>
            <p className="text-capitalize mb-3 text-black">{single_invoice?.employer?.email}</p>
         </div>

         <div className='border-bottom'>
            <h6 className="font-weight-bold mb-2 text-black">Invoice Number:</h6>
            <p className="text-capitalize mb-3 text-black">{single_invoice?.invoice_number}</p>
         </div>

         <div className='border-bottom'>
            <h6 className="font-weight-bold mb-2 text-black">Due Date:</h6>
            <p className="text-capitalize mb-3 text-black">{ single_invoice?.due_date ?   getDate(single_invoice?.due_date) : "Not Added"}</p>
         </div>

         <div className='border-bottom'>
            <h6 className="font-weight-bold mb-2 text-black">Contact No:</h6>
            <p className="text-capitalize mb-3 text-black">{single_invoice?.employer?.mobile}</p>
         </div>

         <div className='border-bottom'>
            <h6 className="font-weight-bold mb-2 text-black">Invoice Date:</h6>
            <p className="text-capitalize mb-3 text-black">{getDate(single_invoice?.date)}</p>
         </div>

         <div className='border-bottom'>
            <h6 className="font-weight-bold mb-2 text-black">Amount Due:</h6>
            <p className="text-capitalize mb-3 text-black">£{single_invoice?.amount_due}</p>
         </div>


         <div className='border-bottom'>
            <h6 className="font-weight-bold mb-2 text-black">Total Amount:</h6>
            <p className="text-capitalize mb-3 text-black">£470.00</p>
         </div>
         <div className='d-flex flex-wrap mb-2' style={{ gap: "2rem" }}>
            <div style={{ flexBasis: "calc(50% - 1rem)" }}>
               <h6 className="text-black mb-2">Payment Term:</h6>
               {single_invoice?.payment_terms ?
                 (
                   <>
                     <select className="btn-md mb-3 px-5 py-2" style={{ borderRadius: "1.3rem", backgroundColor: "#edf2f4", width: "100%" }}>
                        <option value="net30">{single_invoice?.payment_terms}</option>
                     </select>
                   </>
                ):
                (
                <>
                  <p className="btn-md mb-3 px-5 py-2"> Not Added </p>
                </>
                ) 
               }
            </div>

            <div style={{ flexBasis: "calc(50% - 1rem)" }}>
               <h6 className="text-black  mb-2">Payment Method:</h6>
               {/* <select className="btn-md mb-3 px-5 py-2" style={{ borderRadius: "1.3rem", backgroundColor: "#edf2f4", width: "100%" }}>
                  <option value="credit_card">{single_invoice?.payment_method}</option>
               </select> */}
                 {single_invoice?.payment_method ?
                 (
                   <>
                     <select className="btn-md mb-3 px-5 py-2" style={{ borderRadius: "1.3rem", backgroundColor: "#edf2f4", width: "100%" }}>
                        <option value="net30">{single_invoice?.payment_method}</option>
                     </select>
                   </>
                ):
                (
                <>
                  <p className="btn-md mb-3 px-5 py-2"> Not Added </p>
                </>
                ) 
               } 
            </div>

            <div style={{ flexBasis: "calc(50% - 1rem)" }}>
               <h6 className="text-black  mb-2">Payment Status:</h6>
               {/* <select className="btn-md mb-3 px-5 py-2" style={{ borderRadius: "1.3rem", backgroundColor: "#edf2f4", width: "100%" }}>
                  <option value="paid">{single_invoice?.payment_status}</option>
               </select> */}

               {single_invoice?.payment_status ?
                 (
                   <>  
                      <select className="btn-md mb-3 px-5 py-2" style={{ borderRadius: "1.3rem", backgroundColor: "#edf2f4", width: "100%" }}>
                        <option value="net30">{single_invoice?.payment_status}</option>
                      </select>
                   </>
                ):
                (
                <>
                   <p className="btn-md mb-3 px-5 py-2"> Not Added </p>
                </>
                ) 
               } 
            </div>

            <div style={{ flexBasis: "calc(50% - 1rem)" }}>
               <h6 className="text-black  mb-2">Group Invoice:</h6>
               {/* <select className="btn-md mb-3 px-5 py-2" style={{ borderRadius: "1.3rem", backgroundColor: "#edf2f4", width: "100%" }}>
                  <option value="yes">{single_invoice?.group_invoice}</option>
               </select> */}

               {single_invoice?.group_invoice ?
                 (
                   <>
                     <select className="btn-md mb-3 px-5 py-2" style={{ borderRadius: "1.3rem", backgroundColor: "#edf2f4", width: "100%" }}>
                        <option value="net30">{single_invoice?.group_invoice}</option>
                     </select>
                   </>
                ):
                (
                 <>
                     <p className="btn-md mb-3 px-5 py-2"> Not Added </p>
                 </>
                ) 
               } 
            </div>
         </div>
      </Stack>
   )
   
   const handleClearFilter = () => {   
      setStartDatePicker(null)
      setDueDate(null)
      dispatch(allInvoices({pageno:pageNumber}))
   }

 

   return (
      <>
         {isLoading && !search ? (
            <Spinner />
         ) : (
            <span>
               <Nav />
               <div className="content-body">
                  <div className="container-fluid">
                     <div className="h-80">
                        <div className="mb-4 row justify-between align-items-center">
                           <div className="col-xl-4 col-lg-7 mb-2">
                              <h1 className="text-black fs-35 font-w600 mb-3">Invoices</h1>
                              <h6 className="fs-16 mb-1">{userPerPage} Rows per page</h6>
                           </div>
                           <div className="col-xl-8 col-lg-5 mb-2">
                              <Form className="d-flex flex-wrap gap-2 justify-content-end">
                                 <div className="date-picker-container position-relative">
                                    {/* <div className="placeholder-text">From</div> */}
                                    <ReactDatePicker
                                       selected={startDatePicker}
                                       onChange={(date) => setStartDatePicker(date)}
                                       className="form-control rounded-4 border-dark w-100 custom-dropdown-toggle bg-transparent py-4 float-end "
                                       dateFormat="dd/MM/yyyy"
                                       placeholderText="Pending Date"
                                       isClearable
                                    />
                                    <i className="fa-solid fa-calendar-minus fa-lg position-absolute calendar-icon " style={{ color: '#00B094' }} />
                                 </div>
                                 <div className="date-picker-container position-relative">
                                    {/* <div className="placeholder-text">Due Date</div> */}
                                    <ReactDatePicker
                                       selected={dueDate}
                                       onChange={(date) => setDueDate(date)}
                                       className="form-control rounded-4 border-dark w-100 custom-dropdown-toggle bg-transparent py-4 float-end "
                                       dateFormat="dd/MM/yyyy"
                                       placeholderText="Due Date"
                                       isClearable
                                    />
                                    <i className="fa-solid fa-calendar-minus fa-lg position-absolute calendar-icon " style={{ color: '#00B094' }} />
                                 </div>

                                 <div className=" rounded-4 bg-transparent p-3 d-flex align-items-center justify-content-center" style={{ border: '1px solid #c4c4c4' }}>
                                    Total Invoice: <span className="text-success mx-1"> £{totalAmount}</span>
                                 </div>
                                 <div
                                   className="border border-dark rounded-4 d-flex align-items-center p-2"
                                     style={{
                                       maxWidth: "260px",
                                       height: "55px", // Fixed height of 58px
                                       overflow: "hidden",
                                    }}
                                     >
                                    <span
                                       className="me-2 text-success"
                                       style={{ fontSize: "0.8rem" }}
                                       >
                                       <i className="fas fa-search"></i>
                                    </span>
                                     <input
                                        type="text"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        placeholder="Search name and email" 
                                        className="form-control border-0 bg-transparent text-black shadow-none"
                                        style={{
                                          color: "#6C757D",
                                          height: "40px", // Adjusted height for input to fit within 58px
                                       }}
                                   />
                                </div>
                              </Form>
                              <Link
                                 to="#"
                                 className="float-end text-success text-right mx-4 text-decoration-underline mt-3"
                                 onClick={() => {handleClearFilter()}}
                                 style={{ cursor:'pointer' }}
                              >
                                 Clear All
                              </Link>
                           </div>
                        </div>
                        <div className="row">
                           <div className="col-xl-12">
                              <div className="table-responsive">
                                 <div
                                    id="example5_wrapper"
                                    className="dataTables_wrapper no-footer"
                                 >
                                    <table
                                       className="table display mb-4 dataTablesCard card-table dataTable no-footer"
                                       id="example5"
                                       role="grid"
                                       aria-describedby="example5_info"
                                    >
                                       <thead>
                                          <tr role="row">

                                             <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="ID: activate to sort column ascending"
                                             >
                                                <div className="checkbox mr-0 align-self-center mt-0">
                                                   <div className="custom-control custom-checkbox ">
                                                      <input type="checkbox" className="custom-control-input" id="checkAll"  onChange={handleAllCheckBox} checked={checkAllCheckBox}/>
                                                      <label className="custom-control-label" htmlFor="checkAll" />
                                                   </div>
                                                </div>
                                             </th>
                                             <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="ID: activate to sort column ascending"
                                                style={{ width: 100 }}
                                             >
                                                Id
                                             </th>
                                             <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Company: activate to sort column ascending"
                                                style={{ paddingLeft: '18px', width: 150 }}
                                             >
                                                Logo
                                             </th>
                                             <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Company: activate to sort column ascending"
                                                style={{ width: 150 }}
                                             >
                                                Name
                                             </th>
                                             <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Company: activate to sort column ascending"
                                             >
                                                Company Name
                                             </th>
                                             <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Contact: activate to sort column ascending"
                                             >
                                                Invoice No
                                             </th>

                                             <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Date Applied: activate to sort column ascending"
                                             >
                                                Invoice Date
                                             </th>
                                             <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Date Applied: activate to sort column ascending"
                                             >
                                                Due Date
                                             </th>
                                             <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Company: activate to sort column ascending"
                                             >
                                                Address
                                             </th>
                                             <th
                                                className="sorting"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Contact: activate to sort column ascending"
                                             >
                                                Action
                                             </th>
                                          </tr>
                                       </thead>
                                       <tbody>{getInvoices()}</tbody>
                                    </table>
                                    <div className="d-flex align-items-center justify-content-between pb-2">
                                       <div
                                          className="dataTables_info"
                                          id="example5_info"
                                          role="status"
                                          aria-live="polite"
                                       >
                                          Showing {pageVisted + 1}
                                          of {total_pages} pages
                                       </div>
                                       <div
                                          className="dataTables_paginate paging_simple_numbers"
                                          id="example5_paginate"
                                       >
                                          <ReactPaginate
                                             previousLabel={"Previous"}
                                             nextLabel={"Next"}
                                             pageCount={pageCount}
                                             onPageChange={changePage}
                                             containerClassName={"paginationBttns"}
                                             previousLinkClassName={
                                                "paginate_button previous previousBttn"
                                             }
                                             nextLinkClassName={
                                                "paginate_button next nextBttn"
                                             }
                                             pageLinkClassName={"paginate_button mr-1 ml-1"}
                                             disabledClassName={"paginationDisabled"}
                                             activeClassName={"paginationActive"}
                                             forcePage={pageNumber}
                                          />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </span>
         )}

       {
         mode === "edit" ?
   (
         <>
         <CustomeModal
            title="Edit Invoice"
            show={isModalOpen}
            onHide={handleCloseModal}
            content={isLoading ? "Loading..." : <InvoiceForm invoice_data={invoice_detail?.body} handleCloseModal={handleCloseModal}/>}
         /> 
         </>
   ) :
   (
         <CustomeModal
           title="Invoice"
           show={isModalOpen}
           onHide={handleCloseModal}
           content={isLoading ? 'Loading...': invoiceSummary}
        />  
   )}  
      </>
   );
}

export default Invoices;
