import axios from 'axios';

// Get All admin
const BASE_URL = process.env.REACT_APP_BASEURL;

const getAllAdmin = async (token, data) => {

    let pageno = data?.pageno ? data?.pageno : 1;


    let url = `${BASE_URL}/admin/all?pageno=${pageno}`;
    if(data?.search){
        url += `&search=${data.search}`
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await axios.get(url, config);
    if (response.data.body) {
        return response.data;
    }
};

const createAdmin = async (token, data) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await axios.post(`${BASE_URL}/admin/signup`, data, config);
    if (response.data.body) {
        return response.data;
    }
};

const toggleSubAdminStatus = async (token, data) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await axios.put(`${BASE_URL}/admin/toggle/status/${data.subAdmin_id}?pageno=${data.pageno}`, data, config);
    if (response.data.body) {
        return response.data;
    }
}

const changePassowrd = async (token, data) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await axios.put(`${BASE_URL}/admin/changePassword`, data, config);
    if (response.data.body) {
        return response.data;
    }
}

const adminService = {
    getAllAdmin, createAdmin, toggleSubAdminStatus, changePassowrd
};

export default adminService;
