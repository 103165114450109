import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Modal } from "react-bootstrap"; // Assuming react-bootstrap is being used
import { useDispatch, useSelector } from "react-redux";
import { allInvoices, reset, updateInvoice } from "../../../features/invoices/invoiceSlice";
import dateFormat from "dateformat";

const InvoiceForm = ({invoice_data ,handleCloseModal}) => {

console.log(invoice_data ,'invoice_data')   

const dispatch = useDispatch();

const getDate = (date) => {
    return dateFormat(date, "yyyy-mm-dd ");
 };

 const { isLoading } = useSelector(
    (state) => state.invoices
 );

 const [formValues, setFormValues] = useState({
    due_date: null,
    payment_terms: null,
    payment_status: null,
    payment_method: null,
    group_invoice: null,
  });
  const [buttonLoading, setButtonLoading] = useState(false);

  // Separate arrays for each dropdown
  const paymentTermsOptions = [
    { key: "30_days", label: "30 Days" },
    { key: "60_days", label: "60 Days" },
    { key: "one_week", label: "One Week" },
    { key: "two_weeks", label: "Two Weeks" },
    { key: "one_month", label: "One Month" },
  ];

  const paymentStatusOptions = [
    { key: "paid", label: "Paid" },
    { key: "unpaid", label: "Unpaid" },
  ];

  const paymentMethodOptions = [
    { key: "cash", label: "Cash" },
    { key: "paypal", label: "PayPal" },
    { key: "bank_transfer", label: "Bank Transfer" },
    { key: "credit_card", label: "Credit Card" },
  ];

  const groupInvoiceOptions = [
    { key: "include", label: "Include" },
    { key: "exclude", label: "Exclude" },
  ];

  const handleDateChange = (field, date) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: date,
    }));
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    setButtonLoading(true);
    // Simulate form submission process
    const data = {
        timesheet_id: invoice_data?._id,
        ...formValues,
        ...(formValues.due_date && { due_date: getDate(formValues.due_date) }),
      };
    dispatch(updateInvoice(data))
    .then((result) => {
    if (result.meta.requestStatus === "fulfilled") {
      handleCloseModal()
      dispatch(allInvoices({pageno:1}));
    }
  })
  .catch((error) => {
    console.error("Error updating invoice:", error);
  });
 
    setButtonLoading(false);
  };



 useEffect(()=>{
    setFormValues({
        due_date: invoice_data?.due_date || null,
        payment_terms: invoice_data?.payment_terms || null,
        payment_status: invoice_data?.payment_status  || null,
        payment_method: invoice_data?.payment_method  || null,
        group_invoice: invoice_data?.group_invoice || null,
    })
    return () => dispatch(reset()) 
  },[invoice_data])



  return (
    <form
      className="form-group"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <div className="mb-4 d-flex align-items-center" style={{ gap: "10px" }}>
        <label>Due Date:</label>
        <div className="date-picker-container position-relative">
          <ReactDatePicker
            selected={formValues.due_date || null}
            onChange={(date) => handleDateChange("due_date", date)}
            className="form-control rounded-4 border-dark w-100 bg-transparent py-2 px-3"
            dateFormat="dd/MM/yyyy"
            placeholderText="Select Due Date"
            isClearable
          />
        </div>
      </div>

      <div>
        <label>Payment Terms:</label>
        <select
          name="payment_terms"
          value={formValues.payment_terms || ""}
          onChange={handleSelectChange}
          className="form-control rounded-3 my-3"
        >
          <option value="" disabled>
            Select Payment Terms
          </option>
          {paymentTermsOptions.map((option) => (
            <option key={option.key} value={option.key}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label>Payment Status:</label>
        <select
          name="payment_status"
          value={formValues.payment_status || ""}
          onChange={handleSelectChange}
          className="form-control rounded-3 my-3"
        >
        <option value="" disabled>
            Select Payment Status
        </option>
          {paymentStatusOptions.map((option) => (
            <option key={option.key} value={option.key}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label>Payment Method:</label>
        <select
          name="payment_method"
          value={formValues.payment_method || ""}
          onChange={handleSelectChange}
          className="form-control rounded-3 my-3"
        >
        <option value="" disabled>
            Select Payment Method
        </option>
          {paymentMethodOptions.map((option) => (
            <option key={option.key} value={option.key}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label>Group Invoice:</label>
        <select
          name="group_invoice"
          value={formValues.group_invoice || ""}
          onChange={handleSelectChange}
          className="form-control rounded-3 my-3"
        >
        <option value="" disabled>
            Select Group Invoice
        </option>
          {groupInvoiceOptions.map((option) => (
            <option key={option.key} value={option.key}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <Modal.Footer>
        <Button
          type="submit"
          variant="success"
          className="btn-block rounded-4 mt-4"
        >
          {isLoading ? "Loading..." : "Save"}
        </Button>
        <Button
          className="btn-block rounded-4"
          onClick={()=>handleCloseModal()}
          style={{ backgroundColor: "#21127b", color: "#fff" }}
        >
          Close
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default InvoiceForm;




