import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getToken } from '../../utils/getToken';
import adminService from './adminService';

const initialState = {
    admin: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    result: {},
};

// Get All Admin
export const allAdmin = createAsyncThunk('admin/all', async (data, thunkAPI) => {
    const token = getToken();
    try {
        if (token) {
            return await adminService.getAllAdmin(token, data);
        }
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const createAdmin = createAsyncThunk('admin/create', async (data, thunkAPI) => {
    const token = getToken();
    try {
        if (token) {
            return await adminService.createAdmin(token, data);
        }
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const toggleSubAdminStatus = createAsyncThunk('admin/toggleSubAdminStatus', async (data, thunkAPI) => {
    const token = getToken();
    try {
        if (token) {
            return await adminService.toggleSubAdminStatus(token, data);
        }
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const changePassowrd = createAsyncThunk('admin/changePassword', async (data, thunkAPI) => {
    const token = getToken();
    try {
        if (token) {
            return await adminService.changePassowrd(token, data);
        }
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        reset: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(allAdmin.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
                state.isError = false;
            })
            .addCase(allAdmin.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.admin = action.payload;
            })
            .addCase(allAdmin.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.admin = null;
            })
            .addCase(createAdmin.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
                state.isError = false;
            })
            .addCase(createAdmin.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
            })
            .addCase(createAdmin.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(toggleSubAdminStatus.pending, (state) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = false;
            })
            .addCase(toggleSubAdminStatus.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isError = false;
                state.admin = action.payload;
            })
            .addCase(toggleSubAdminStatus.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(changePassowrd.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
                state.isError = false;
            })
            .addCase(changePassowrd.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isError = false;
            })
            .addCase(changePassowrd.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            });

    },
});

export const { reset } = adminSlice.actions; // Use adminSlice here
export default adminSlice.reducer;
