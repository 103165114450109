import axios from 'axios';

// Get All Invoices
const BASE_URL = process.env.REACT_APP_BASEURL;

const getAllInvoices = async (token, data) => {

    let pageno = data?.pageno ? data?.pageno : 1;

    let url = `${BASE_URL}/admin/invoices?pageno=${pageno}`

    if (data?.pending_date && !data?.due_date) {
        url = `${BASE_URL}/admin/invoices?pageno=${pageno}&pending_date=${data?.pending_date}`
    }
    if(data?.due_date && !data?.pending_date){
        url = `${BASE_URL}/admin/invoices?pageno=${pageno}&due_date=${data?.due_date}`
    }

    if(data?.due_date && data?.pending_date){
        url = `${BASE_URL}/admin/invoices?pageno=${pageno}&due_date=${data?.due_date}&pending_date=${data?.pending_date}`
    }

    if(data?.search){
        url += `&search=${data?.search}`
    }

    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await axios.get(url, config);
    if (response.data.body) {
        return response.data;
    }
};

const updateInvoice = async (token, data) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await axios.put(`${BASE_URL}/admin/invoice`,data, config);
    if (response.data.body) {
        return response.data;
    }
};


const invoiceDetails = async (token, timesheet_id) => {

    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await axios.get(`${BASE_URL}/admin/invoice/${timesheet_id}`, config);
    if (response.data.body) {
        return response.data;
    }
};

const invoiceService = {
    getAllInvoices, updateInvoice ,invoiceDetails
};

export default invoiceService;
