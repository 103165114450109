import axios from 'axios';

// Get All strikes
const BASE_URL = process.env.REACT_APP_BASEURL;

const getAllStrikes = async (token, data) => {

    let pageno = data?.pageno ? data?.pageno : 1;

    let url = `${BASE_URL}/admin/strikes?pageno=${pageno}`
    if(data?.search){
        url += `&search=${data?.search}`
    }
    if(data?.count){
        url += `&count=${data?.count}`
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await axios.get(url, config);
    if (response.data.body) {
        return response.data;
    }
};

const createStrike = async (token, data) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const response = await axios.post(`${BASE_URL}/admin/strike`,data, config);
    if (response.data.body) {
        return response.data;
    }
};

const getSingleWorkerStrike = async (token, data) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await axios.get(`${BASE_URL}/admin/strikes/${data?.id}`, config);
    if (response.data.body) {
        return response.data;
    }
};



const strikeService = { getAllStrikes, createStrike  , getSingleWorkerStrike };

export default strikeService;
