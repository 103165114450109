import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Dropdown, Form } from "react-bootstrap";
import Nav from "../../layouts/nav";
import ReactPaginate from "react-paginate";
import Spinner from "../../components/Spinner";
import CustomeModal from "../../components/customeModal/CustomeModal";
import AddUserModal from "./AddUserModal";

import {
  getAllOnboardingAdmins,
  toggleAdminStatus,
} from "../../../features/onboarding-admin/onboardingAdminSlice";
import ChangePassword from "./ChangePassword";

function OnboardingAdmins() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [search, setSearch] = useState(null);

  const changePage = async (data) => {
    setPageNumber(data.selected);
    dispatch(
      getAllOnboardingAdmins({
        pageno:data.selected + 1,
        status: selectedStatus,
        search: search,
      })
    );
  };

  const handleSelectOption = (key, event) => {
    const ariaLabel = event.target.getAttribute("aria-label");
    switch (ariaLabel) {
      case "Status":
        setSelectedStatus(key);
        break;
      default:
        break;
    }
  };

  const handleOpenPasswordModal = (admin) => {
    setSelectedAdmin(admin);
    setIsPasswordModalOpen(true);
  };

  const handleClosePasswordModal = () => {
    setIsPasswordModalOpen(false);
  };

  const handleAddUserModalOpen = () => setIsAddUserModalOpen(true);
  const handleAddUserModalClose = () => setIsAddUserModalOpen(false);

  const { user } = useSelector((state) => state.auth);
  const { adminUser, isError, message, isLoading } = useSelector(
    (state) => state?.onboardingAdmin
  );

  const allOnboardingAdminUsers = adminUser?.body;

  useEffect(() => {
    setPageNumber(0);
    dispatch(
      getAllOnboardingAdmins({
        pageno:1,
        status: selectedStatus,
        search: search,
      })
    );
  }, [pageNumber, selectedStatus, search]);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
  }, [user, navigate, isError, message]);

  const userPerPage = 10;
  const pageVisted = pageNumber;
  let pageCount;
  const total_pages = adminUser?.total_pages;

  const getOnboardingAdmins = () => {
    pageCount = total_pages;
    return (
      Array.isArray(allOnboardingAdminUsers) &&
      allOnboardingAdminUsers?.map((admin, index) => {
        const handleToggleStatus = () => {
          dispatch(toggleAdminStatus({ user_id: admin._id }))
            .unwrap()
            .then(() => {
              dispatch(
                getAllOnboardingAdmins({
                  pageno: pageNumber === 0 ? 1 : pageNumber,
                })
              );
              admin.status = !admin.status;
              toast.success("Status updated successfully");
            })
            .catch((error) => {
              toast.error(error || "Failed to update status");
            });
        };

        return (
          <tr role="row" className="odd" key={index}>
            <td>{index + 1 + (pageNumber === 0 ? 0 : pageNumber * 10)}.</td>

            <td>
              <div className="media">
                <div className="media-body text-nowrap">
                  <h6 className="fs-16 mb-0">{admin.name}</h6>
                </div>
              </div>
            </td>

            <td>
              <div className="media">
                <div className="media-body text-nowrap">{admin.email}</div>
              </div>
            </td>

            <td>
              <div className="media">
                <div className="media-body text-nowrap">
                  <div className="text-black font-w600 fs-16 mb-0">
                    <button
                      className="btn btn-rounded btn-sm btn-light w-75"
                      onClick={handleToggleStatus}
                    >
                      <span
                        className={
                          admin.status ? "text-success" : "text-danger"
                        }
                      >
                        {admin?.status ? "Active" : "Inactive"}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </td>

            <td>
              <Button
                variant="link"
                className="p-0 text-primary border-0 focus-ring-0 shadow-none"
                onClick={() => handleOpenPasswordModal(admin)}
              >
                Change Password
              </Button>
            </td>
          </tr>
        );
      })
    );
  };

  const statusDropDownOptions = [
    { key: "all", label: "All" },
    { key: "active", label: "Active" },
    { key: "inactive", label: "Inactive" },
  ];

  const labelStatus = statusDropDownOptions?.find(
    (item) => item.key === selectedStatus
  )?.label;

  return (
    <>
      {isLoading && !search ? (
        <Spinner />
      ) : (
        <span>
          <Nav />
          <div className="content-body">
            <div className="container-fluid">
              <div className="h-80">
                <div
                  className="d-flex justify-content-between"
                  style={{ alignItems: "center" }}
                >
                  <div className="mb-2">
                    <h1 className="text-black fs-35 font-w600 mb-1">
                      Onboarding Admins
                    </h1>
                    <h6>{userPerPage} Rows per page</h6>
                  </div>

                  <div
                    className="d-flex gap-2 justify-content-end"
                    style={{ width: "50%" }}
                  >
                    <div className="col-lg-6 col-sm-12 mb-2">
                      <Form
                        className="d-flex flex-wrap gap-2  justify-content-end"
                        style={{ width: "100%" }}
                      >
                        <div
                          className="border border-dark rounded-4 d-flex align-items-center p-2"
                          style={{
                            maxWidth: "260px",
                            height: "55px", // Fixed height of 58px
                            overflow: "hidden",
                          }}
                        >
                          <span
                            className="me-2 text-success"
                            style={{ fontSize: "0.8rem" }}
                          >
                            <i className="fas fa-search"></i>
                          </span>
                          <input
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Search name and email"
                            className="form-control border-0 bg-transparent text-black shadow-none"
                            style={{
                              color: "#6C757D",
                              height: "40px", // Adjusted height for input to fit within 58px
                            }}
                          />
                        </div>
                        <Dropdown
                          onSelect={(key) =>
                            handleSelectOption(key, {
                              target: { getAttribute: () => "Status" },
                            })
                          }
                          className="flex-grow-1"
                          style={{ maxWidth: "150px" }}
                        >
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            className="py-3 rounded-4 border-dark  w-100 custom-dropdown-toggle bg-transparent text-success"
                          >
                            {/* <span className="mx-3" style={{ color: '#6C757D' }}>{selectedStatus}</span> */}
                            <span className="mx-3" style={{ color: "#6C757D" }}>
                              {labelStatus ? labelStatus : "All"}
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {statusDropDownOptions?.map((option) => (
                              <Dropdown.Item
                                key={option.key}
                                eventKey={option.key}
                              >
                                {option.label}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Form>
                    </div>
                    <Button
                      type="button"
                      className="btn btn-success rounded-5 mb-4 py-3 btn-md"
                      onClick={handleAddUserModalOpen}
                    >
                      Add New Admin
                    </Button>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-12">
                    <div className="table-responsive">
                      <div
                        id="example5_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <table
                          className="table display mb-4 dataTablesCard card-table dataTable no-footer"
                          id="example5"
                          role="grid"
                          aria-describedby="example5_info"
                        >
                          <thead>
                            <tr role="row">
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="ID: activate to sort column ascending"
                              >
                                Id
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Company: activate to sort column ascending"
                              >
                                Name
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Contact: activate to sort column ascending"
                              >
                                Email
                              </th>

                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Contact: activate to sort column ascending"
                              >
                                Status
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Actions: activate to sort column ascending"
                              >
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>{getOnboardingAdmins()}</tbody>
                        </table>
                        <div className="d-flex align-items-center justify-content-between pb-2">
                          <div
                            className="dataTables_info"
                            id="example5_info"
                            role="status"
                            aria-live="polite"
                          >
                            Showing {pageVisted + 1}
                            of {total_pages} pages
                          </div>
                          <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="example5_paginate"
                          >
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"paginationBttns"}
                              previousLinkClassName={
                                "paginate_button previous previousBttn"
                              }
                              nextLinkClassName={
                                "paginate_button next nextBttn"
                              }
                              pageLinkClassName={"paginate_button mr-1 ml-1"}
                              disabledClassName={"paginationDisabled"}
                              activeClassName={"paginationActive"}
                              forcePage={pageNumber}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>
      )}
      <CustomeModal
        title="Change Password"
        show={isPasswordModalOpen}
        onHide={handleClosePasswordModal}
        // content={contentChangePassword}
        content={
          <ChangePassword
            handleClosePasswordModal={handleClosePasswordModal}
            selectedAdmin={selectedAdmin}
          />
        }
      />

      <CustomeModal
        title="Add User"
        show={isAddUserModalOpen}
        onHide={handleAddUserModalClose}
        content={
          <AddUserModal handleAddUserModalClose={handleAddUserModalClose} />
        }
      />
    </>
  );
}

export default OnboardingAdmins;
