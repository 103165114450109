import { useEffect, useState } from "react";
import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { getSettings, putSettings, reset } from "../../../features/settings/settingsSlice";
import { toast } from "react-toastify";
import Nav from "../../layouts/nav";
import Spinner from "../../components/Spinner";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

const Setting = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const { user } = useSelector((state) => state.auth);
   const { data, isLoading, isError, isSuccess, message } = useSelector(
      (state) => state.settings
   );

   const settingdata = {
      inputs: [
         { "name": "vat", "label": "VAT %", "type": "number", "min": 0, "max": 100, "placeholder": "VAT %" },
         { "name": "ni", "label": "NI %", "type": "number", "min": 0, "max": 100, "placeholder": "NI %" },
         { "name": "fee", "label": "Fee %", "type": "number", "min": 0, "max": 100, "placeholder": "Min Break in Hours" },
         { "name": "min_break", "label": "Min Break in Hours", "type": "number", "min": 1, "max": 24, "placeholder": "Fee %" },
         { "name": "job_reposting", "label": "Job Reposting %", "type": "number", "min": 0, "max": 100, "placeholder": "Job Responding" },
         { "name": "rest_period", "label": "Rest Period", "type": "number", "min": 1, "max": 24, "placeholder": "Rest Period" },
         { "name": "max_hours_daily", "label": "Max Hours/Day", "type": "number", "min": null, "max": null, "placeholder": "Max Hours/Day" },
         { "name": "max_bookings", "label": "Max Bookings", "type": "number", "min": null, "max": null, "placeholder": "Max Bookings" },
         { "name": "max_hours_weekly", "label": "Max Hours/Week", "type": "number", "min": null, "max": null, "placeholder": "Max Hours/Week" },
         { "name": "student_max_hours_daily", "label": "Student Max Hours/Day", "type": "number", "min": null, "max": null, "placeholder": "Student Max Hours/Day" },
         { "name": "student_max_hours_week", "label": "Student Max Hours/Week", "type": "number", "min": null, "max": null, "placeholder": "Student Max Hours/Week" },
         { "name": "min_hour_job", "label": "Min Shift Hours", "type": "number", "min": null, "max": null, "placeholder": "Min Shift Hours" },
         { "name": "max_hour_job", "label": "Max Shift Hours", "type": "number", "min": null, "max": null, "placeholder": "Max Shift Hours" },
         { "name": "timeBetweenJobsMins", "label": "Time Between Jobs Mins", "type": "number", "min": null, "max": null, "placeholder": "Time Between Job Mins" }
      ]
   }  


   useEffect(() => {
      if (isError) {
         toast.error(message);
      }
      // if (isSuccess) {
      //    toast.success(message);
      // }
      if (!user) {
         navigate("/admin/login");
      }
      dispatch(getSettings())

      return () => reset();
   }, [user, navigate, isError, isSuccess, message, dispatch]);

   const settingsData = data?.body;

   const [formData, setFormData] = useState({});

   useEffect(() => {
      if (settingsData) {
         setFormData(settingsData);
      }
   }, [data])

   const saveSettings = async () => {
      const data = {
         ...formData,
         vat: Number(formData.vat),
         ni: Number(formData.ni),
      }
      dispatch(putSettings(data));
   }

   const handleInput = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => ({
         ...prev,
         [name]: value,
      }));
   }

   return (
      <>
         {isLoading ? (
            <Spinner />
         ) : (
            <span>
               <Nav />

               <div className="content-body">
                  <div className="container-fluid">
                     <Row>
                        <Col xl="12">
                           <Card>
                              <Card.Header className="d-block card-header">
                                 <Card.Title><b>Settings </b></Card.Title>
                              </Card.Header>
                              <Card.Body className="card-body">
                                 <div className="d-flex align-items-center flex-wrap bg-white rounded py-3 px-md-3 px-0 mb-4">
                                    {settingdata.inputs.map((input) => (
                                       <div className="col-lg-6 mt-4" key={input.name}>
                                          <label htmlFor={`input-${input.name}`}><b>{input.label}</b></label>
                                          <input
                                             id={`input-${input.name}`}
                                             className="col-lg-12 form-control rounded-4 mr-auto mb-md-0 mb-3"
                                             type={input.type}
                                             name={input.name}
                                             placeholder={input.placeholder}
                                             min={input.min}
                                             max={input.max}
                                             value={formData[input.name] || ''}
                                             onChange={handleInput}
                                          />
                                       </div>
                                    ))}
                                    <div className="col-lg-12 text-center mt-4">
                                       <button
                                          onClick={() => saveSettings()}
                                          className="btn btn-rounded btn-md mt-5 px-5 btn-success w-50"
                                       >
                                          Save
                                       </button>
                                    </div>
                                 </div>

                              </Card.Body>
                           </Card>
                        </Col>
                     </Row>
                  </div>
               </div>
            </span>
         )}
      </>
   );
};

export default Setting;
