import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASEURL

const timeslots = async (data,token) => {
    let pageno = data?.pageno || 1;
    let url = `${BASE_URL}/admin/timeslots?pageno=${pageno}`;
    if(data?.status){ 
        if(data?.status !== 'all'){
            url = `${BASE_URL}/admin/timeslots?pageno=${pageno}&status=${data?.status}`;
        }
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(url, config);
    if (response.data.body) {
        return response.data;
    }
}


const createTimeSlot = async (token, data) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.post(`${BASE_URL}/admin/timeslots`, {title: data.title}, config);
    if (response.data.body) {
        return response.data;
    }
}

const updateTimeSlot = async (token, data) => {
    console.log({service: data})
    const timeslot_id = data.id;
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.put(`${BASE_URL}/admin/timeslots/${timeslot_id}`, {is_active: data.is_active}, config);
    if (response.data.body) {
        return response.data;
    }
}




const timeslotService = {
    timeslots, createTimeSlot, updateTimeSlot
}


export default timeslotService;