import React, { useEffect, useState } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import DatePicker, {
  DateObject,
  getAllDatesInRange,
} from "react-multi-date-picker";
import size from "react-element-popper/animations/size";
import Btn from "react-multi-date-picker/components/button";
import CustomeModal from "./customeModal/CustomeModal";
import ToggleBtn from "./ToggleButton";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import moment from "moment-timezone";
import {
  getJobTemplate,
  postAddDateAndTime,
} from "../../features/templateJob/employerTemplateJobSlice";
import { useDispatch, useSelector } from "react-redux";
import { TimePicker } from "react-ios-time-picker";
import { putJobDateTimeForEditJob } from "../../features/jobs/jobslice";
import { Spinner as Loader } from "react-bootstrap";
import { all } from "axios";

const Calender = ({
  templateId,
  jobroleId,
  isBlockBookingChecked,
  token,
  editingMode,
  job,
  setIsBlockBookingChecked,
  smallLoading,
  handleCloseCalendarModal,
}) => {
  const dispatch = useDispatch();

  const { jobTemplate } = useSelector((state) => state?.jobTemplate);

  const [dates, setDates] = useState([]);
  const [allDates, setAllDates] = useState([]);
  // const [qtyValues, setQtyValues] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedQty, setSelectedQty] = useState(null);
  const [datesArray, setDatesArray] = useState([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [showEditTimeModal, setShowEditTimeModal] = useState(false);
  const [isToggleChecked, setIsToggleChecked] = useState(false);
  const [breakTime, setBreakTime] = useState(0);
  const [isBreakPaid, setIsBreakPaid] = useState(false);
  const [startTime, setStartTime] = useState("09:00");
  const [endTime, setEndTime] = useState("17:00");
  const [selectedDateRecords, setSelectedDateRecords] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [showQtyAll, setShowQtyAll] = useState(false);
  const [qtyAllValue, setQtyAllValue] = useState(null);

  const handleDateSelectCheckboxChange = (index, date) => {
    setSelectedDateRecords((prev) => {
      const exists = prev.find((item) => item.index === index);
      if (exists) {
        return prev.filter((item) => item.index !== index); // Remove if already selected
      } else {
        return [...prev, { index, ...date }]; // Store index + full date object
      }
    });
  };

  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;
    setIsAllSelected(checked);

    if (checked) {
      setSelectedDateRecords(
        datesArray.map((date, index) => ({ index, ...date }))
      ); // Store all objects
    } else {
      setSelectedDateRecords([]); // Clear selection
    }
  };
  let alreadyDate = Array.isArray(jobTemplate?.body?.datetime?.datetime);

  const handleBreakTimeChange = (e) => setBreakTime(parseInt(e.target.value));

  const handleIsBreakPaidChange = (e) => setIsBreakPaid(e.target.value);

  const handleToggleChange = () => setIsToggleChecked(!isToggleChecked);

  const setValues = () => {
    if (jobTemplate?.body?.datetime?.datetime?.length > 0) {
      const values = jobTemplate?.body?.datetime?.datetime?.map((el) => {
        const dateTimeString = el.start_date; // Assuming start_date contains both date & time

        // Extract separate date and time
        const startDate = moment(dateTimeString, "DD/MM/YYYY HH:mm").format(
          "DD/MM/YYYY"
        );
        const startTime = moment(dateTimeString, "DD/MM/YYYY HH:mm").format(
          "HH:mm"
        );

        const endDateString = el.end_date; // Assuming end_date contains both date & time
        const endDate = moment(endDateString, "DD/MM/YYYY HH:mm").format(
          "DD/MM/YYYY"
        );
        const endTime = moment(endDateString, "DD/MM/YYYY HH:mm").format(
          "HH:mm"
        );

        return {
          qty: el.qty,
          start_date: startDate, // Set extracted date
          end_date: endDate, // Set extracted end date
          is_overnight: el.is_overnight || false,
          is_break: el?.is_break || false,
          is_break_paid: el?.is_break_paid || false,
          start_time: startTime, // Set extracted start time
          end_time: endTime, // Set extracted end time
          break_time: el?.break_time || 0,
        };
      });
      setDatesArray([...values]);
    }
  };

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  const handleShowEditTimeModal = () => {
    setShowEditTimeModal(true);
    if (selectedDateRecords?.length === 1) {
      const selectedRecord = selectedDateRecords[0];
      setStartTime(selectedRecord.start_time);
      setEndTime(selectedRecord.end_time);
      setIsToggleChecked(selectedRecord.is_break);
      setBreakTime(selectedRecord.break_time);
      setIsBreakPaid(selectedRecord.is_break_paid ? "yes" : "no");
    } else {
      setStartTime("09:00");
      setEndTime("17:00");
      setIsToggleChecked(false);
      setBreakTime("0");
      setIsBreakPaid(false);
    }
  };
  const handleCloseEditTimeModal = () => {
    setShowEditTimeModal(false);
    const updatedSelectedRecords = selectedDateRecords?.map((record) => ({
      ...record,
      start_time: startTime || record.start_time, // Update start time if provided
      end_time: endTime || record.end_time, // Update end time if provided
      is_break: isToggleChecked, // Update break allowed
      break_time: isToggleChecked ? breakTime : "0", // Set break time or reset to 0
      is_break_paid: isToggleChecked ? isBreakPaid === "yes" : false, // Update break paid
    }));
    setSelectedDateRecords(updatedSelectedRecords);
    // Update `datesArray` to reflect changes in selected records
    setDatesArray((prevDates) => {
      const updatedDatesArray = prevDates.map((date, index) => {
        const updatedRecord = updatedSelectedRecords.find(
          (record) => record.index === index
        );
        return updatedRecord ? { ...date, ...updatedRecord } : date; // Update if match found, else keep original
      });
      return updatedDatesArray; // Return the updated array
    });
  };

  const handleShow = ({ index, qty }) => {
    setShow(true);
    setSelectedQty({
      index: index,
      qty: qty, // Default to 1 if qty is undefined
    });
  };


  const handleClose = () => {
    setShow(false);
  };
  const handleSubmitForSingleQty = () => {
    const updatedDatesArray = datesArray.map((date, index) =>
      index === selectedQty?.index
        ? { ...date, qty: Number(selectedQty?.qty) }
        : date
    );
    setDatesArray(updatedDatesArray);
    setSelectedQty(null);
    handleClose();
  };

  const handleShowQtyAll = () => {
    setShowQtyAll(true);
    setQtyAllValue(null);
  };

  const handleCloseQtyAll = () => {
    setShowQtyAll(false);
  };

  const handleSubmitForQtyAll = () => {
    const updatedDatesArray = datesArray.map((date) => ({
      ...date,
      qty: qtyAllValue,
    }));
    setDatesArray(updatedDatesArray);
    console.log(updatedDatesArray, "updatedDatesArray");
    handleCloseQtyAll();
  };

  const handleQtyChange = (index, newQty) => {
    setSelectedQty({ index: index, qty: newQty });
  };
  const handleQtyAllChange = (value) => {
    setQtyAllValue(value);
  };

  const handleStartTimeChange = (time) => {
    setStartTime(time);
  };
  const handleEndTimeChange = (time) => {
    setEndTime(time);
  };

  const handleSubmit = async () => {
    const data = {
      template_id: templateId,
      jobrole_id: jobroleId,
      is_range: isChecked ? "true" : "false",
      token: token,
      apply_to_all: isCheckboxChecked && dates.length > 1 ? "true" : "false",
      is_block_booking: isBlockBookingChecked ? "true" : "false",
      dates: datesArray.map((date, index) => {
        let is_overnight = false;
        const startMoment = moment(date.start_time, "hh:mm a");
        const endMoment = moment(date.end_time, "hh:mm a");
        if (endMoment.isAfter(startMoment, "day")) {
          is_overnight = false;
        }
        return {
          start_date: `${date.start_date} ${date.start_time}`,
          end_date: `${date.start_date} ${date.end_time}`,
          qty: date.qty,
          is_overnight: is_overnight,
          is_break: date.is_break,
          ...(date.is_break && {
            break_time: date.break_time.toString(),
            is_break_paid: date.is_break_paid,
          }),
        };
      }),
    };
    dispatch(postAddDateAndTime(data)).then(() => {
      dispatch(getJobTemplate(token)).then(() => {
        handleCloseEditTimeModal();
        handleCloseCalendarModal();
      });
    });
  };

  const handleSubmitForEditJob = async () => {
    const data = {
      job_id: job?._id,
      is_range: isChecked ? "true" : "false",
      token: token,
      apply_to_all: isCheckboxChecked && dates.length > 1 ? "true" : "false",
      is_block_booking: isBlockBookingChecked ? "true" : "false",
      dates: dates.map((date, index) => {
        const is_break = isToggleChecked;
        const break_time = breakTime || 0;
        // const qty = qtyValues?.at(index)?.qty || 1;
        const is_break_paid = isBreakPaid === "yes" ? true : false;
        let is_overnight = false;
        // Check if end time is earlier than start time and not on the same day
        if (endMoment.isAfter(startMoment, "day")) {
          is_overnight = false;
        }
        return {
          // hired: "0",
          start_date: `${date.format("DD/MM/YYYY")} ${startTime}`,
          end_date: `${date.format("DD/MM/YYYY")} ${endTime}`,
          // qty: qty === undefined ? 0 : qty,
          is_overnight: is_overnight,
          is_break: is_break,
          ...(is_break && {
            break_time: break_time.toString(),
            is_break_paid: is_break_paid,
          }),
        };
      }),
    };
    // Send data to API
    dispatch(putJobDateTimeForEditJob(data)).then(() => {
      handleCloseEditTimeModal();
    });
  };

  // Parse the times into Moment objects
  const startMoment = moment(startTime, "hh:mm a");
  const endMoment = moment(endTime, "hh:mm a");

  // Calculate the difference in hours and minutes
  const duration = moment.duration(endMoment.diff(startMoment));
  const hours = duration.hours();
  const minutes = duration.minutes();

  const formattedOutput = `${hours} hours, ${minutes} minutes`;

  const qtyContent = (
    <Stack>
      <div className="text-center" style={{ width: "40%" }}>
        <input
          type="number"
          value={selectedQty?.qty}
          onChange={(e) => handleQtyChange(selectedQty?.index, e.target.value)}
          className="w-100 p-3 border-0 text-center w-25"
          style={{ borderRadius: "1.4rem", backgroundColor: "#edf2f4" }}
        />
      </div>
      <div className="text-end">
        <Button
          variant="primary"
          size="lg"
          className="mt-4 rounded-xl w-25"
          onClick={handleSubmitForSingleQty}
        >
          Ok
        </Button>
      </div>
    </Stack>
  );

  const qtyContentForEditJob = (
    <Stack>
      <div className="text-center" style={{ width: "40%" }}>
        <input
          value={qtyAllValue}
          onChange={(e) => handleQtyAllChange(e.target.value)}
          type="number"
          className="w-100 p-3 border-0 text-center w-25"
          style={{ borderRadius: "1.4rem", backgroundColor: "#edf2f4" }}
          placeholder="Enter Qty"
        />
      </div>
      <div className="text-end">
        <Button
          variant="primary"
          size="lg"
          className="mt-4 rounded-xl w-25"
          onClick={handleSubmitForQtyAll}
        >
          Ok
        </Button>
      </div>
    </Stack>
  );

  const editTimeModal = (
    <Stack>
      <div>
        <p className="mb-0">Selected Start and End dates</p>
        <div className="d-flex gap-1">
          {selectedDateRecords?.map((date, index) => (
            <p className="mb-0 text-black" key={index}>
              {date?.start_date} {date?.start_time} - {date?.end_date}{" "}
              {date?.end_time}
              {index !== dates.length - 1 && ", "}{" "}
              {/* Add comma and space for all dates except the last one */}
            </p>
          ))}
        </div>
        <p className="mb-0">Set start and end time for selected dates</p>
      </div>
      <div className="d-flex justify-content-between align-items-center my-3">
        <div className="row">
          <div className="col-6 d-flex gap-2 align-items-center w-25">
            <p className="mb-0">
              <i>Start</i>
            </p>
            <div>
              <TimePicker
                onChange={handleStartTimeChange}
                value={startTime}
                popupClassName="start_time_input"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 d-flex gap-2 align-items-center ml-3">
            <p className="mb-0">
              <i>End</i>
            </p>
            <div>
              <TimePicker onChange={handleEndTimeChange} value={endTime} />
            </div>
          </div>
        </div>
      </div>
      <div className="text-c enter">
        <p>{formattedOutput}</p>
      </div>
      <div className="d-flex gap-4 align-items-center">
        <p className="mb-0">Break Allowed</p>
        <ToggleBtn
          label={""}
          isChecked={isToggleChecked}
          handleChange={handleToggleChange}
        />
      </div>

      {isToggleChecked && (
        <div className="d-flex justify-content-around align-items-center mt-4">
          <div>
            <h6>Break (Mins)</h6>
            <Form.Select
              className="rounded-xl"
              onChange={handleBreakTimeChange}
              value={breakTime}
            >
              <option>0</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="45">45</option>
              <option value="60">60</option>
              <option value="90">90</option>
              <option value="120">120</option>
            </Form.Select>
          </div>
          <div>
            <h6>Break Paid</h6>
            <Form.Select
              className="rounded-xl"
              value={isBreakPaid}
              onChange={handleIsBreakPaidChange}
            >
              <option>Select</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </Form.Select>
          </div>
        </div>
      )}
      <div className="d-flex align-items-center gap-4 justify-content-end mt-4">
        <p
          className="mb-0 c-pointer text-black font-weight-bold"
          onClick={handleCloseEditTimeModal}
        >
          Cancel
        </p>
        {/* <p className='mb-0 c-pointer text-black font-weight-bold' onClick={editingMode ? handleSubmitForEditJob : handleSubmit}>Ok</p> */}
        <p
          className="mb-0 c-pointer text-black font-weight-bold"
          onClick={handleCloseEditTimeModal}
        >
          Ok
        </p>
      </div>
    </Stack>
  );

  useEffect(() => {
    if (
      Array.isArray(jobTemplate?.body?.datetime?.datetime) &&
      jobTemplate?.body?.datetime?.datetime?.length > 0 &&
      allDates?.length === 0
    ) {
      console.log(
        jobTemplate?.body?.datetime?.datetime,
        "jobTemplate?.body?.datetime?.datetime"
      );
      setValues();
    }
  }, [alreadyDate && allDates?.length === 0]);
  return (
    <div>
      <div className="d-flex gap-4 mb-4 align-items-center">
        <p className="mb-0">Individual Dates</p>
        <ToggleBtn
          label="Date Range"
          isChecked={isChecked}
          handleChange={handleChange}
        />
      </div>
      <p className="font-weight-bold">
        Select first and last date for consecutive date
      </p>
      <div>
        <DatePicker
          range={isChecked ? true : false}
          calendarPosition="bottom-left"
          fixMainPosition
          value={dates}
          multiple={isChecked ? false : true}
          render={<Btn />}
          animations={[size()]}
          // minDate={new DateObject().toFirstOfMonth()}
          // maxDate={new DateObject().toLastOfMonth()}
          minDate={new DateObject()}
          onChange={(dateObjects) => {
            if (isChecked) {
              const dates= getAllDatesInRange(dateObjects)
              setAllDates(getAllDatesInRange(dateObjects));
              const convertsDates = dates?.map((date, index) => {
                return {
                  qty: 1,
                  start_date: `${date.format("DD/MM/YYYY")}`,
                  end_date: `${date.format("DD/MM/YYYY")}`,
                  is_overnight: false,
                  is_break: false,
                  is_break_paid:  false,
                  start_time: "09:00",
                  end_time: "17:00",
                  break_time: '0',
                };
              });
  
              setDatesArray((previous) => {
                const existingDates = new Set(previous.map((item) => item.start_date));
                const newDates = convertsDates.filter((newDate) => !existingDates.has(newDate.start_date));
                return [...previous, ...newDates];
              });
            } else {
              const convertsDates = dateObjects?.map((date, index) => {
                return {
                  qty: 1,
                  start_date: `${date.format("DD/MM/YYYY")}`,
                  end_date: `${date.format("DD/MM/YYYY")}`,
                  is_overnight: false,
                  is_break: false,
                  is_break_paid:  false,
                  start_time: "09:00",
                  end_time: "17:00",
                  break_time: '0',
                };
              });
              setDatesArray((previous) => {
                const existingDates = previous?.map((item) => item.start_date);
                const newDates = convertsDates?.filter((newDate) => !existingDates.includes(newDate.start_date));
                if(newDates.length === 0) {
                  return convertsDates;
                }
                return [...previous, ...newDates];
              });
              setDates(dateObjects);
              setAllDates(dateObjects);
            }
          }}
        />
      </div>
      <div>
        <div className="d-flex justify-content-between align-items-center my-3">
          <h6 className="mb-0 font-weight-bold">Shifts</h6>
          <Button
            size="lg"
            disabled={selectedDateRecords?.length === 0}
            className={`p-0 c-pointer bg-transparent ${
              selectedDateRecords?.length === 0 && "text-black"
            } border-0 font-weight-bold`}
            style={{
              color: `${selectedDateRecords?.length > 0 && "#2FE6DE"}`,
              fontSize: "15px !important",
            }}
            onClick={handleShowEditTimeModal}
          >
            Edit Time
          </Button>
        </div>
        <CustomeModal
          title="Edit Times"
          show={showEditTimeModal}
          onHide={handleCloseEditTimeModal}
          content={editTimeModal}
        />
        <div className="w-100 px-2 mb-2">
          <div className="text-black d-flex justify-content-between align-items-center font-weight-bold">
            <p className="mb-0">
              <input
                type="checkbox"
                checked={isAllSelected}
                onChange={handleSelectAllChange}
              />
            </p>
            <p className="mb-0">Start</p>
            <p className="mb-0">End</p>
            <div className="d-flex align-items-center gap-2">
              <p className="mb-0">Qty</p>
              <i
                className="fa-solid fa-pen-to-square d-flex align-items-end c-pointer"
                style={{ color: "#2FE6DE" }}
                onClick={() => handleShowQtyAll()}
              ></i>
            </div>
          </div>
        </div>

        {/* {editingMode ? (
          <div>
            {dates?.length > 0 ? (
              <div>
                {allDates?.map((date, index) => (
                  <div
                    className="mb-2 d-flex justify-content-between align-items-center py-2 pl-2 pr-3 rounded-xl"
                    style={{ backgroundColor: "#e7ecef" }}
                    key={index}
                  >
                    <input
                      type="checkbox"
                      name=""
                      id={date}
                      onChange={(e) =>
                        handleDateSelectCheckboxChange(
                          e,
                          date.format("DD/MM/YYYY")
                        )
                      }
                    />
                    <p className="mb-0 fs-12">
                      {date.format("DD/MM/YYYY")} {startTime}
                    </p>
                    <p className="mb-0 fs-12">
                      {date.format("DD/MM/YYYY")} {endTime}
                    </p>
                    <div className="d-flex align-items-center gap-2">
                      <p
                        className="mb-0 fs-12"
                        style={{ border: "1px solid green" }}
                      >
                        {date?.qty || 1}
                      </p>
                      <i
                        className="fa-solid fa-pen-to-square d-flex align-items-end c-pointer"
                        style={{ color: "#2FE6DE" }}
                        onClick={() => handleShow({ index: index })}
                      ></i>
                    </div>
                  </div>
                ))}
                <CustomeModal
                  title=""
                  show={show}
                  onHide={handleClose}
                  content={qtyContentForEditJob}
                />
              </div>
            ) : (
              <div>
                {job?.summary?.datetime?.datetime?.map((date, index) => (
                  <div
                    className="mb-2 d-flex justify-content-between align-items-center py-2 pl-2 pr-3 rounded-xl"
                    style={{ backgroundColor: "#e7ecef" }}
                    key={index}
                  >
                    <input
                      type="checkbox"
                      name=""
                      id={date?.start_date}
                      onChange={(e) =>
                        handleDateSelectCheckboxChange(e, date.start_date)
                      }
                    />
                    <p className="mb-0 fs-12">
                      {date.start_date} {date.start_time}
                    </p>
                    <p className="mb-0 fs-12">
                      {date.end_date} {date.end_time}
                    </p>
                    <div className="d-flex align-items-center gap-2">
                      <p
                        className="mb-0 fs-12"
                        style={{ border: "1px solid red" }}
                      >
                        {date.qty}
                      </p>
                      <i
                        className="fa-solid fa-pen-to-square d-flex align-items-end c-pointer"
                        style={{ color: "#2FE6DE" }}
                        onClick={() => handleShow(date.start_date)}
                      ></i>
                    </div>
                  </div>
                ))}
                <CustomeModal
                  title=""
                  show={show}
                  onHide={handleClose}
                  content={qtyContentForEditJob}
                />
              </div>
            )}
          </div>
        ) : (
          <div>
            {dates?.length > 0 ? (
              <div>
                {datesArray?.map((date, index) => (
                  <React.Fragment key={index}>
                    <div
                      className="mb-2 d-flex justify-content-between align-items-center py-2 pl-2 pr-3 rounded-xl"
                      style={{ backgroundColor: "#e7ecef" }}
                    >
                      <input
                        type="checkbox"
                        checked={selectedDateRecords.some(
                          (item) => item.index === index
                        )}
                        onChange={() =>
                          handleDateSelectCheckboxChange(index, date)
                        }
                      />
                      <p className="mb-0 fs-12">
                        {date?.start_date} {date.start_time}
                      </p>
                      <p className="mb-0 fs-12">
                        {date?.end_date} {date.end_time}
                      </p>
                      <div className="d-flex align-items-center gap-2">
                        <p className="mb-0 fs-12">{date.qty}</p>
                        <i
                          className="fa-solid fa-pen-to-square d-flex align-items-end c-pointer"
                          style={{ color: "#2FE6DE" }}
                          onClick={() =>
                            handleShow({ index: index, qty: date.qty })
                          }
                        ></i>
                      </div>
                    </div>

                    <p className="mb-0 fs-12">
                      {date?.is_break ? (
                        <p style={{ padding: "2px" }}>
                          Break Time: {date?.break_time} mins,
                          {date?.is_break_paid ? "Paid" : " Unpaid"}
                        </p>
                      ) : (
                        <p style={{ padding: "2px" }}>No Break Time</p>
                      )}
                    </p>
                  </React.Fragment>
                ))}
                <CustomeModal
                  title=""
                  show={show}
                  onHide={handleClose}
                  content={qtyContent}
                />
              </div>
            ) : (
              <div>
                {jobTemplate?.body?.datetime?.datetime?.map((date, index) => (
                  <div
                    className="mb-2 d-flex justify-content-between align-items-center py-2 pl-2 pr-3 rounded-xl"
                    style={{ backgroundColor: "#e7ecef" }}
                    key={index}
                  >
                    <input
                      type="checkbox"
                      name=""
                      id={date?.start_date}
                      onChange={(e) =>
                        handleDateSelectCheckboxChange(e, date?.start_date)
                      }
                    />
                    <p className="mb-0 fs-12">{date?.start_date} </p>
                    <p className="mb-0 fs-12">{date?.end_date} </p>
                    <div className="d-flex align-items-center gap-2">
                      <p className="mb-0 fs-12">{qtyValues?.at(index)?.qty}</p>
                      <i
                        className="fa-solid fa-pen-to-square d-flex align-items-end c-pointer"
                        style={{ color: "#2FE6DE" }}
                        onClick={() => handleShow({ index: index })}
                      ></i>
                    </div>
                  </div>
                ))}


                <CustomeModal
                  title=""
                  show={show}
                  onHide={handleClose}
                  content={qtyContent}
                />
              </div>
            )}
          </div>
        )} */}
        {datesArray?.map((date, index) => (
          <React.Fragment key={index}>
            <div
              className="mb-2 d-flex justify-content-between align-items-center py-2 pl-2 pr-3 rounded-xl"
              style={{ backgroundColor: "#e7ecef" }}
            >
              <input
                type="checkbox"
                checked={selectedDateRecords.some(
                  (item) => item.index === index
                )}
                onChange={() => handleDateSelectCheckboxChange(index, date)}
              />
              <p className="mb-0 fs-12">
                {date?.start_date} {date.start_time}
              </p>
              <p className="mb-0 fs-12">
                {date?.end_date} {date.end_time}
              </p>
              <div className="d-flex align-items-center gap-2">
                <p className="mb-0 fs-12">{date.qty}</p>
                <i
                  className="fa-solid fa-pen-to-square d-flex align-items-end c-pointer"
                  style={{ color: "#2FE6DE" }}
                  onClick={() => handleShow({ index: index, qty: date.qty })}
                ></i>
              </div>
            </div>

            <p className="mb-0 fs-12">
              {date?.is_break ? (
                <p style={{ padding: "2px" }}>
                  Break Time: {date?.break_time} mins,
                  {date?.is_break_paid ? "Paid" : " Unpaid"}
                </p>
              ) : (
                <p style={{ padding: "2px" }}>No Break Time</p>
              )}
            </p>
          </React.Fragment>
        ))}

        <CustomeModal
          title=""
          show={show}
          onHide={handleClose}
          content={qtyContent}
        />
        <p className="mb-0 text-end c-pointer" style={{ color: "#2FE6DE" }}>
          Show less
        </p>
      </div>

      <div className="d-flex gap-4 align-items-center my-4">
        <p className="mb-0">Post job as a block booking?</p>
        <input
          type="checkbox"
          name=""
          id=""
          checked={isBlockBookingChecked}
          onChange={() => setIsBlockBookingChecked(!isBlockBookingChecked)}
        />
      </div>
      <Button
        size="lg"
        className="btn-rounded"
        variant="success"
        onClick={() =>
          editingMode ? handleSubmitForEditJob() : handleSubmit()
        }
      >
        {smallLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Loader />
          </div>
        ) : (
          "Save"
        )}
      </Button>
      <CustomeModal
        title="Edit Qty for all dates"
        show={showQtyAll}
        onHide={handleCloseQtyAll}
        content={qtyContentForEditJob}
      />
    </div>
  );
};

export default Calender;
