import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASEURL

// get All Industries
const getAllIndustries = async (token, data) => {

    let pageno = data?.pageno || 1;
    let url = `${BASE_URL}/admin/industries?pageno=${pageno}`;
    if (data?.search) {
        url += `&search=${data.search}`
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(url, config);
    if (response.data.body) {
        return response.data;
    }
}


// toggle Industry Status
const toggleStatus = async (token, industry_id, pageno) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.put(`${BASE_URL}/admin/industry/status/${industry_id}?pageno=${pageno}`, null, config);
    if (response.data.body) {
        return response.data;
    }
}

// Add New Industry
const addIndustry = async (token, data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  
    const configImage = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    };
  
    if (typeof data.icon === "object") {
      const FormData = require("form-data");
      let imagedata = new FormData();
      imagedata.append("file", data.icon);
      const imageRes = await axios.post(
        `${BASE_URL}/file/upload`,
        imagedata,
        configImage
      );
  
      if (imageRes?.data?.body) {
        delete data?.icon;
        data.icon = imageRes?.data?.body?.fileName;
      }
    } else {
      delete data?.icon;
    }
  
    const response = await axios.post(`${BASE_URL}/admin/industry`, data, config);
    return response.data; // Return the full response to handle in the thunk
  };
  
  // Edit Industry
  const editIndustry = async (token, data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
  
    const configImage = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    };
  
    if (typeof data.icon !== "string") {
      const FormData = require("form-data");
      let imagedata = new FormData();
      imagedata.append("file", data.icon);
      const imageRes = await axios.post(
        `${BASE_URL}/file/upload`,
        imagedata,
        configImage
      );
  
   
  
      if (imageRes?.data?.body) {
        delete data?.icon;
        data.icon = imageRes?.data?.body?.fileName;
      }
    } else {
      data.icon = data.icon.split("/").pop();
    }
  
    const response = await axios.put(
      `${BASE_URL}/admin/industry/${data?.id}`,
      data,
      config
    );
  
    return response.data; // Return the full response to handle in the thunk
  };

const industriesService = {
    getAllIndustries, toggleStatus ,addIndustry ,editIndustry
}


export default industriesService;