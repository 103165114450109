import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getToken } from '../../utils/getToken';
import invoiceService from './invoiceService';

const initialState = {
    invoices: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    result:{},
    invoice_detail:{}
};

// Get All Invoices
export const allInvoices = createAsyncThunk('admin/invoices', async (data, thunkAPI) => {
    const token = getToken();
    try {
        if (token) {
            return await invoiceService.getAllInvoices(token, data);
        }
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const updateInvoice = createAsyncThunk('admin/invoices/edit', async (data, thunkAPI) => {
    const token = getToken();
    try {
        if (token) {
            return await invoiceService.updateInvoice(token, data);
        }
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});


export const singleInvoice = createAsyncThunk('admin/invoices/single', async (timesheet_id, thunkAPI) => {
    const token = getToken();
    try {
        if (token) {
            return await invoiceService.invoiceDetails(token, timesheet_id);
        }
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const invoiceSlice = createSlice({
    name: 'invoices',
    initialState,
    reducers: {
        reset: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(allInvoices.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
                state.isError = false;
            })
            .addCase(allInvoices.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.invoices = action.payload;
            })
            .addCase(allInvoices.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.invoices = null;
            }).addCase(updateInvoice.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
                state.isError = false;
            })
            .addCase(updateInvoice.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.invoice_detail = action.payload;
            })
            .addCase(updateInvoice.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.invoice_detail = null;
            }).addCase(singleInvoice.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
                state.isError = false;
            })
            .addCase(singleInvoice.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.invoice_detail = action.payload;
            })
            .addCase(singleInvoice.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.invoice_detail = null;
            });
    },
});

export const { reset } = invoiceSlice.actions;

export default invoiceSlice.reducer;
