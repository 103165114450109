import axios from 'axios';

// get All Workers Users
const BASE_URL = process.env.REACT_APP_BASEURL
const getAllTemplates = async (token, data) => {
    let pageno = data?.pageno ? data?.pageno : 1;
    let url = `${BASE_URL}/admin/templates?pageno=${pageno}`;
    if (data?.search) {
        url += `&search=${data.search}`
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(url, config);
    if (response.data.body) {
        return response.data;
    }
}


const toggleStatus = async (token, pageno, template_id) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.put(`${BASE_URL}/admin/template/toggle/${template_id}?pageno=${pageno}`, null, config);
    if (response.data.body) {
        return response.data;
    }
}

const templateService = {
    getAllTemplates, toggleStatus
}


export default templateService;