import React, { useEffect, useState } from 'react';
import Nav from "../../layouts/nav";
import { Table, Button, Spinner, Stack, Dropdown } from 'react-bootstrap';
import { format, startOfMonth, endOfMonth, startOfWeek, endOfWeek, addDays, subMonths, addMonths, isSameMonth, isSameDay, startOfDay } from 'date-fns';
import StatusDropdown from './StatusDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { onboardingSlots, updateAppointmentStatus } from '../../../features/onboarding-admin/onboardingAdminSlice';
import { Form, Link } from 'react-router-dom';
import CustomeModal from '../../components/customeModal/CustomeModal';

const OnboardingCalendar = () => {

    const dispatch = useDispatch();

    const [currentDate, setCurrentDate] = useState(new Date());
    const [view, setView] = useState('month');
    const [selectedEvents, setSelectedEvents] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [focusedDate, setFocusedDate] = useState(null); // To track focus
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        dispatch(onboardingSlots(format(currentDate, 'dd/MM/yyyy')));
    }, [currentDate, dispatch]);

    const { slots, isLoading } = useSelector(state => state.onboardingAdmin);

    const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

    const getEventsForDate = (date) => {
        const dateStr = format(date, 'yyyy-MM-dd');
        const eventForDate = slots?.body?.find((event) => event.title === dateStr);
        return eventForDate ? eventForDate : [];
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };


    const getCalendarDays = () => {
        if (view === 'month') {
            const startDay = startOfWeek(startOfMonth(currentDate));
            const endDay = endOfWeek(endOfMonth(currentDate));
            const calendar = [];

            let day = startDay;
            while (day <= endDay) {
                calendar.push(day);
                day = addDays(day, 1);
            }
            return calendar;
        } else if (view === 'week') {
            const startDay = startOfWeek(currentDate);
            const endDay = endOfWeek(currentDate);
            const calendar = [];

            let day = startDay;
            while (day <= endDay) {
                calendar.push(day);
                day = addDays(day, 1);
            }
            return calendar;
        } else {
            const day = startOfDay(currentDate);
            return [day];
        }
    };

    const calendar = getCalendarDays();

    const handlePrev = () => {
        setSelectedDate(null);
        setCurrentDate((prevDate) => {
            if (view === 'week') {
                return addDays(prevDate, -7); // Move back by one week
            } else if (view === 'day') {
                return addDays(prevDate, -1); // Move back by one day
            } else {
                return subMonths(prevDate, 1); // Move back by one month
            }
        });
    };

    const handleNext = () => {
        setSelectedDate(null);
        setCurrentDate((prevDate) => {
            if (view === 'week') {
                return addDays(prevDate, 7); // Move forward by one week
            } else if (view === 'day') {
                return addDays(prevDate, 1); // Move forward by one day
            } else {
                return addMonths(prevDate, 1); // Move forward by one month
            }
        });
    };

    const handleToday = () => {
        setCurrentDate(new Date());
    };

    const handleDateClick = (date) => {
        setSelectedDate(date);
        setSelectedEvents(getEventsForDate(date));
    };


    const handleToggleStatus = (id, status ,worker_id) => {
        console.log(id, status, worker_id)
        setSelectedStatus({
            id: id,
            status: status,
        }); 
        setIsModalOpen(true)
        setSelectedOption(null)
        // try {
        //     const appointment = {
        //         onboarding_id: id,
        //         status: status,
        //     };
        //     dispatch(updateAppointmentStatus(appointment));
        //     // Update the Redux state directly
        //     dispatch(onboardingSlots(format(currentDate, 'dd/MM/yyyy')));
        //     console.log('Selected Date:', selectedDate);
        //     setSelectedEvents(getEventsForDate(selectedDate));
        // } catch (error) {
        //     console.error('Failed to update status:', error);
        // }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
           try {
            const appointment = {
                onboarding_id: selectedStatus?.id,
                status: selectedStatus?.status,
                user_role:selectedOption
            };
            dispatch(updateAppointmentStatus(appointment));
            // Update the Redux state directly
            dispatch(onboardingSlots(format(currentDate, 'dd/MM/yyyy')));
            setSelectedEvents(getEventsForDate(selectedDate));
            setIsModalOpen(false);
        } catch (error) {
            console.error('Failed to update status:', error);
        }
    };

    const options = [
        { id: 1, label: "Student", value: "student" },
        { id: 2, label: "Non Student", value: "non_student" }
      ];
    
     
    
      const handleSelect = (value) => {
        setSelectedOption(value);
        console.log("Selected:", value);
      };

    console.log({ selectedStatus })
    const confirmationContent = (
        <Stack gap={3}>
            <form onSubmit={handleSubmit}>
                <Dropdown
                    className="flex-grow-1"
                    style={{ maxWidth: '100%', marginTop: '20px' }}
                    onSelect={handleSelect}
                >
                    <Dropdown.Toggle
                        id="dropdown-basic"
                        className="py-3 rounded-4 border-dark w-100 custom-dropdown-toggle bg-transparent text-success d-flex align-items-center justify-content-between pl-1 mr-2"
                    >
                        <span className="mx-3" style={{ color: '#6C757D' }}>
                            {selectedOption ? options.find((opt) => opt.value === selectedOption)?.label : "Select user role"}
                        </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ width: '100%', maxHeight: '300px', overflowY: 'auto' }}>
                        {options.map((option) => (
                            <Dropdown.Item key={option.id} eventKey={option.value}>
                                {option.label}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
    
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <Button type="submit" variant="primary" className="rounded-4">
                        OK
                    </Button>
                </div>
            </form>
        </Stack>
    );


    return (
        <>
            <Nav />
            <div className="content-body">
                {isLoading ?
                    <div className='d-flex justify-content-center align-items-center mt-5'>
                        <Spinner animation="border" variant="primary" />
                    </div> :
                    <div className="container-fluid">
                        <div className="h-80">
                            <div className="d-flex justify-content-between ">
                                <div className="mb-2">
                                    <h1 className="text-black fs-35 font-w600 mb-1">
                                        Onboarding
                                    </h1>
                                </div>
                            </div>
                            <div>
                                <div className="d-flex align-items-center justify-content-between mt-3 bg-white p-1">
                                    <div className="d-flex gap-1">
                                        <Button variant="primary" size="sm" style={{ width: "45px" }} onClick={handlePrev}>&lt;</Button>
                                        <Button variant="primary" size="sm" style={{ width: "45px" }} onClick={handleNext}>&gt;</Button>
                                        <Button variant="primary" size="sm" style={{ width: "70px" }} onClick={handleToday}>Today</Button>
                                    </div>
                                    <div>
                                        <h4 style={{ width: "160px" }}>{format(currentDate, 'MMMM yyyy')}</h4>
                                    </div>
                                    <div>
                                        <Button
                                            variant={view === 'month' ? 'primary' : 'outline-primary'}
                                            onClick={() => setView('month')}
                                            className="mx-1"
                                            size="sm"
                                            style={{ width: "75px" }}
                                        >
                                            Month
                                        </Button>
                                        <Button
                                            variant={view === 'week' ? 'primary' : 'outline-primary'}
                                            onClick={() => setView('week')}
                                            className="mx-1"
                                            size="sm"
                                            style={{ width: "75px" }}
                                        >
                                            Week
                                        </Button>
                                        <Button
                                            variant={view === 'day' ? 'primary' : 'outline-primary'}
                                            onClick={() => setView('day')}
                                            className="mx-1"
                                            size="sm"
                                            style={{ width: "75px" }}
                                        >
                                            Day
                                        </Button>
                                    </div>
                                </div>
                                <Table bordered responsive className="mb-5">
                                    <thead>
                                        <tr>
                                            {view !== 'day' &&
                                                daysOfWeek.map((day) => (
                                                    <th key={day} className="text-center">
                                                        {day}
                                                    </th>
                                                ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {view === 'month' &&
                                            Array.from({ length: calendar.length / 7 }, (_, i) => (
                                                <tr key={i}>
                                                    {calendar.slice(i * 7, i * 7 + 7).map((date) => (
                                                        <td
                                                            key={date}
                                                            tabIndex={0}
                                                            onFocus={() => setFocusedDate(date)}
                                                            onBlur={() => setFocusedDate(null)}
                                                            onClick={() => handleDateClick(date)}
                                                            className={`${isSameMonth(date, currentDate) ? '' : 'text-dark'} ${isSameDay(date, new Date()) ? 'bg-info text-white' : isSameDay(date, focusedDate) ? 'bg-light text-black' : ''}`}
                                                            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'lightgray')} // Apply hover effect
                                                            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '')} // Remove hover effect
                                                            style={{ height: '100px', padding: "0px 9px", width: '14.28%', cursor: 'pointer', paddingTop: '10px' }}
                                                        >
                                                            <div className='text-end'>{format(date, 'd')}</div>
                                                            <p className='text-primary mb-0 mt-3'>
                                                                {
                                                                    getEventsForDate(date)?.data?.length > 0
                                                                        ? `${getEventsForDate(date)?.data.length < 10
                                                                            ? "0"
                                                                            : ""}${getEventsForDate(date)?.data.length} Appointments`
                                                                        : ''
                                                                }
                                                            </p>
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        {view === 'week' && (
                                            <tr>
                                                {calendar.map((date) => (
                                                    <td
                                                        key={date}
                                                        onClick={() => handleDateClick(date)}
                                                        className={`${isSameMonth(date, currentDate) ? '' : 'text-dark'} ${isSameDay(date, new Date()) ? 'bg-info text-white' : isSameDay(date, focusedDate) ? 'bg-light text-black' : ''}`}
                                                        style={{ height: '100px', padding: "0px 9px", width: '14.28%' }}
                                                        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'lightgray')}
                                                        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '')}
                                                    >
                                                        <div className='text-end'>{format(date, 'd')}</div>
                                                        <p className='text-primary mb-0 mt-3'>
                                                            {
                                                                getEventsForDate(date)?.data?.length > 0
                                                                    ? `${getEventsForDate(date)?.data.length < 10
                                                                        ? "0"
                                                                        : ""}${getEventsForDate(date)?.data.length} Appointments`
                                                                    : ''
                                                            }
                                                        </p>
                                                    </td>
                                                ))}
                                            </tr>
                                        )}

                                        {view === 'day' && (
                                            <tr>
                                                <td
                                                    className="text-black"
                                                    style={{ height: '100px', padding: "0px 9px", width: '100%' }}
                                                    onClick={() => handleDateClick(currentDate)}
                                                >
                                                    <div className='text-end'>{format(currentDate, 'd')}</div>
                                                    <p className='text-primary mb-0 mt-3'>
                                                        {
                                                            getEventsForDate(currentDate)?.data?.length > 0
                                                                ? `${getEventsForDate(currentDate)?.data.length < 10
                                                                    ? "0"
                                                                    : ""}${getEventsForDate(currentDate)?.data.length} Appointments`
                                                                : ''
                                                        }
                                                    </p>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>

                                {selectedDate && (
                                    <div>
                                        {
                                            selectedEvents?.data?.length > 0
                                                ? <h5>Appointments on {format(selectedDate, 'MMMM dd, yyyy')}:</h5>
                                                : <h5>No Appointments for Today</h5>
                                        }
                                        {
                                            selectedEvents?.data?.length > 0 &&
                                            <Table bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th style={{ backgroundColor: "#1F127A", color: "white" }}>Id</th>
                                                        <th style={{ backgroundColor: "#1F127A", color: "white" }}>Worker Name</th>
                                                        <th style={{ backgroundColor: "#1F127A", color: "white" }} >Worker Email</th>
                                                        <th style={{ backgroundColor: "#1F127A", color: "white" }}>Slot</th>
                                                        <th style={{ backgroundColor: "#1F127A", color: "white" }}>Date</th>
                                                        <th style={{ backgroundColor: "#1F127A", color: "white" }}>Status</th>
                                                        <th style={{ backgroundColor: "#1F127A", color: "white" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {selectedEvents?.data?.map((event, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}.</td>
                                                            <td>{event.worker_name}</td>
                                                            <td>{event.worker_email}</td>
                                                            <td>{event.slot}</td>
                                                            <td>{format(selectedDate, 'MMMM dd, yyyy')}</td>
                                                            <td>
                                                                {
                                                                    event?.status === 'onboarded'
                                                                        ? <Link className="btn btn-rounded btn-light btn-sm text-success" to="#" style={{ textTransform: "capitalize" }}>
                                                                            {selectedStatus?.id === event?._id ? selectedStatus?.status : event?.status}                                                                        </Link>
                                                                        : event?.status === 'pending' ?
                                                                            <Link className="btn btn-rounded btn-light btn-sm text-warning" to="#" style={{ textTransform: "capitalize" }}>
                                                                                {selectedStatus?.id === event?._id ? selectedStatus?.status : event?.status}
                                                                            </Link>
                                                                            :
                                                                            <Link className="btn btn-rounded btn-light btn-sm text-danger" to="#" style={{ textTransform: "capitalize" }}>
                                                                                {selectedStatus?.id === event?._id ? selectedStatus?.status : event?.status}
                                                                            </Link>
                                                                }
                                                            </td>
                                                            <td>
                                                              {
                                                                event?.status === 'pending' ?
                                                               <StatusDropdown event={event} handleToggleStatus={handleToggleStatus} selectedStatus={selectedStatus} /> : ""
                                                               }
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        }
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                }
            </div >
            <CustomeModal
                title={ "Confirmation About Role" }
                show={isModalOpen}
                onHide={handleCloseModal}
                content={confirmationContent}
            />
        </>
    );
};

export default OnboardingCalendar;