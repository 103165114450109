import axios from 'axios';
import { format } from 'date-fns';
const BASE_URL = process.env.REACT_APP_BASEURL

// get All Workers Users //
const getAllWorkers = async (token, data) => {
    try {
        const page = data?.pageno || 1;

        let url = `${BASE_URL}/admin/workers?pageno=${page}`;
        if (data?.selectedStatus) {
          if (data?.selectedStatus === "active") {
            url += `&status=true`;
          }
          if (data?.selectedStatus === "inactive") {
            url += `&status=false`;
          }
        }
        if (data?.selectedApproval) {
          url += `&is_approved=${data.selectedApproval}`;
        }
        if (data?.selectedRating) {
          url += `&rating=${data.selectedRating}`;
        }
        if (data?.selectedShift) {
          url += `&completedShifts=${data.selectedShift}`;
        }
        if (data?.startDatePicker) {
          const date = new Date(data?.startDatePicker);
          const formattedDate = format(date, 'dd/MM/yyyy');
          url += `&joiningDate=${formattedDate}`;
        }
        if (data?.search) {
            url += `&search=${data.search}`;
        }
        if (data?.strike) {
            url += `&strike=${data.strike}`;
        }
        if(data?.user_role){
            url += `&user_role=${data.user_role}`;
        }
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        
        const response = await axios.get(url, config);
        if (response?.data?.body) {
            return response.data;
        }
    } catch (error) {
        throw error
    }
}

// Get Worker Profile //
const getWorkersProfile = async (token, id) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    }

    const response = await axios.get(`${BASE_URL}/admin/worker/${id}`, config);
    if (response.data.body) {
        return response.data;
    }
}

// Toggle Status Approved //
const toggleApproved = async (token, user_id, pageno) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.put(`${BASE_URL}/admin/worker/approved/${user_id}?pageno=${pageno}`, null, config);
    if (response.data.body) {
        return response.data;
    }
}

// Toggle Role //
const toggleRoles = async (token, user_id, pageno , user_role) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.put(`${BASE_URL}/admin/worker/role/${user_id}?pageno=${pageno}`, { user_role : user_role}, config);
    if (response.data.body) {
        return response.data;
    }
}

// Toggle Status //
const toggleStatus = async (token, user_id, pageno) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.put(`${BASE_URL}/admin/worker/status/${user_id}?pageno=${pageno}`, null, config);
    if (response.data.body) {
        return response.data;
    }
}

// Get Worker Availibility
const getWorkerAvailibility = async (token, user_id) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    try {
        return await axios.get(`${BASE_URL}/admin/worker/availability/${user_id}`, config)
    } catch (error) {
        throw error
    }
}


const getWorkerJobs = async (token, data) => {
    const page = data?.pageno || 1;

    let url = `${BASE_URL}/admin/worker/${data?.id}/jobs?pageno=${page}`;
    if (data?.selectedStatus) {
        url += `&status=${data.selectedStatus}`;
    }
    if (data?.start_date && data?.end_date) {
      url += `&from=${data.start_date}&to=${data.end_date}`;
    }

    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    try {
        return await axios.get(url, config)
    } catch (error) {
        throw error
    }
}

const getAllWorkerJobs = async (token, data) => {
    const page = data?.pageno || 1;
    let url = `${BASE_URL}/admin/jobs/workers/all?pageno=${page}`;
    if (data?.status) {
        url += `&status=${data?.status}`;
    }
    if (data?.start_date && data?.end_date) {
      url += `&from=${data.start_date}&to=${data.end_date}`;
    }

    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    try {
        return await axios.get(url, config)
    } catch (error) {
        throw error
    }
}

const getWorkerJobSummary = async ({ job_id ,user_id }, token) => {
    console.log(job_id, user_id, 'job_id, user_id in worker service')
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    try {
        return await axios.get(`${BASE_URL}/admin/job/worker/summary?job_id=${job_id}&user_id=${user_id}`, config);
    } catch (error) {
        console.log(error);
        throw error;
    }
}


const workerService = {
    getAllWorkers, toggleStatus, toggleApproved, getWorkersProfile, getWorkerAvailibility, getWorkerJobs ,getAllWorkerJobs ,getWorkerJobSummary ,toggleRoles
}

export default workerService;