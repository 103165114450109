import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getToken } from '../../utils/getToken';
import homeService from './homeService';



const initialState = {
    dashboardData: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    adminDashboardData: null,
    chartLoading: false,
    jobStatusData: null

}



export const dashboard = createAsyncThunk('onboarding-admin/dashboard', async (thunkAPI) => {
    const token = getToken();
    try {
        return await homeService.dashboard(token);
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});


export const adminDashboard = createAsyncThunk('admin/dashboard', async ( data ,thunkAPI) => {
    const token = getToken();
    try {
        return await homeService.adminHome(data ,token);
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const jobStatusChart = createAsyncThunk('admin/home/job_status', async ( data , thunkAPI) => {
    const token = getToken();
    try {
        return await homeService.jobStatus(data, token);
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});





export const homeSlice = createSlice({
    name: "home",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ""
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(dashboard.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(dashboard.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.dashboardData = action.payload;
            })
            .addCase(dashboard.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.payload;
                state.dashboardData = null;
            }).addCase(adminDashboard.pending, (state) => {
                state.isLoading = true;
                state.isError   = false;
                state.isSuccess = false;
            }).addCase(adminDashboard.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.adminDashboardData = action.payload;
            }).addCase(adminDashboard.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.payload;
                state.adminDashboardData = null;
            }).addCase(jobStatusChart.pending, (state) => {
                state.chartLoading = true;
                state.isError   = false;
                state.isSuccess = false;
            }).addCase(jobStatusChart.fulfilled, (state, action) => {
                state.chartLoading = false;
                state.isSuccess = true;
                state.jobStatusData = action.payload.body;
            }).addCase(jobStatusChart.rejected, (state, action) => {
                state.chartLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.payload;
                state.jobStatusData = null
            }
        )
    }
})
export const { reset } = homeSlice.actions;


export default homeSlice.reducer