import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASEURL

// get All Jobs
const getAllJobRoles = async (data) => {
    const config = {
        headers: {
            Authorization: `Bearer ${data.token}`
        }
    }
    const response = await axios.post(`${BASE_URL}/jobs/industry/jobrole`, { industry_id : data?.industry_id }, config);
    if (response?.data?.body) {
        return response.data;
    }
}

const jobRolesService = {
    getAllJobRoles
}


export default jobRolesService;