import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getToken } from '../../utils/getToken';
import timeslotService from './timeslotService';



const initialState = {
    allTimeSlots: null,
    updatedTimeslot: null,
    createdTimeslot: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ""
}



export const getAllTimeSlots = createAsyncThunk('admin/timeslots', async (data,thunkAPI) => {
    const token = getToken();
    try {
        return await timeslotService.timeslots(data, token);
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const createTimeSlot = createAsyncThunk('admin/create/timeslots', async (data, thunkAPI) => {
    const token = getToken();
    try {
        return await timeslotService.createTimeSlot(token, data);
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const updateTimeSlot = createAsyncThunk('admin/update/timeslots', async (data, thunkAPI) => {
    const token = getToken();
    try {
        return await timeslotService.updateTimeSlot(token, data);
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});



export const timeslotSlice = createSlice({
    name: "timeslot",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ""
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllTimeSlots.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
                state.isError = false;
            })
            .addCase(getAllTimeSlots.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.allTimeSlots = action.payload;
            })
            .addCase(getAllTimeSlots.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.payload;
                state.allTimeSlots = null;
            })
            .addCase(createTimeSlot.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
                state.isError = false;
            })
            .addCase(createTimeSlot.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.createdTimeslot = action.payload;
            })
            .addCase(createTimeSlot.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.payload;
                state.createdTimeslot = null;
            })
            .addCase(updateTimeSlot.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
                state.isError = false;
            })
            .addCase(updateTimeSlot.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.updatedTimeslot = action.payload;
            })
            .addCase(updateTimeSlot.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.payload;
                state.updatedTimeslot = null;
            })
    }
})
export const { reset } = timeslotSlice.actions;


export default timeslotSlice.reducer