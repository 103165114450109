import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Bar, Line } from "react-chartjs-2";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import Nav from "../../../layouts/nav";
import { FeaturedSlide } from "./FeaturedSlide";
import DualLine3 from "../../../CommonComponents/charts/chartJs/dualLine3";

import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  adminDashboard,
  jobStatusChart,
} from "../../../../features/home/homeSlice";
import { toast } from "react-toastify";
import {
  toggleApproved,
  toggleStatus,
} from "../../../../features/workers/workerSlice";
import { format, getYear } from "date-fns";
import Spinner from "../../../components/Spinner";

const Home = () => {
  const dispatch = useDispatch();

  const currentYear = new Date().getFullYear();
  const [selectYear, setSelectYear] = useState(new Date(currentYear, 0, 1));
  const {
    isLoading,
    isError,
    isSuccess,
    message,
    adminDashboardData,
    jobStatusData,
  } = useSelector((state) => state.home);
  const dashboardData = adminDashboardData?.body || [];
  const { user } = useSelector((state) => state.auth);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [startOfWeek, setStartOfWeek] = useState(null);
  const [endOfWeek, setEndOfWeek] = useState(null);
  const [selectedJobStartWeek, setSelectedJobStartWeek] = useState(null);
  const [jobStartWeekStart, setJobStartWeekStart] = useState(null);
  const [jobStartWeekEnd, setJobStartWeekEnd] = useState(null);
  const [selectedKeys, setSelectedKeys] = useState([]); // Default to all keys

  const start_date = startOfWeek
    ? format(new Date(startOfWeek), "yyyy/MM/dd")
    : null;
  const end_date = endOfWeek ? format(new Date(endOfWeek), "yyyy/MM/dd") : null;
  const start_date_day = jobStartWeekStart
    ? format(new Date(jobStartWeekStart), "yyyy/MM/dd")
    : null;
  const end_date_day = jobStartWeekEnd
    ? format(new Date(jobStartWeekEnd), "yyyy/MM/dd")
    : null;
  const year = getYear(selectYear);

  const handleWeekChange = (date) => {
    if (!date) {
      setSelectedWeek(null);
      setStartOfWeek(null);
      setEndOfWeek(null);
      return;
    }

    // Get Monday as the start of the week
    const start = new Date(date);
    start.setDate(date.getDate() - date.getDay() + 1); // Adjust to Monday start

    // Get Sunday as the end of the week
    const end = new Date(start);
    end.setDate(start.getDate() + 6); // Adjust to Sunday end

    // Update states
    setSelectedWeek(date);
    setStartOfWeek(start);
    setEndOfWeek(end);
  };

  const handleYearChange = (date) => {
    const newYear = new Date(date).getFullYear();
    setSelectYear(new Date(newYear, 0, 1));
  };

  const handleJobStartWeekChange = (date) => {
    // setSelectedJobStartWeek(date);
    // if (date) {
    //   setJobStartWeekStart(startOfISOWeek(date));
    //   setJobStartWeekEnd(endOfISOWeek(date));
    // } else {
    //   setJobStartWeekStart(null);
    //   setJobStartWeekEnd(null);
    // }
    if (!date) {
      setSelectedJobStartWeek(null);
      setJobStartWeekStart(null);
      setJobStartWeekEnd(null);
      return;
    }

    // Get Monday as the start of the week
    const start = new Date(date);
    start.setDate(date.getDate() - date.getDay() + 1); // Adjust to Monday start

    // Get Sunday as the end of the week
    const end = new Date(start);
    end.setDate(start.getDate() + 6); // Adjust to Sunday end

    // Update states
    setSelectedJobStartWeek(date);
    setJobStartWeekStart(start);
    setJobStartWeekEnd(end);
  };

  const getDate = (date) => {
    // return dateFormat(date, "yyyy-mm-dd ");
    return format(new Date(date), "yyyy-MM-dd");
  };

  // Find objects dynamically
  const filledJobsData =
    dashboardData?.hiringStatus?.find((item) => item.weeklyFilledJobs)
      ?.weeklyFilledJobs || {};
  const unfilledJobsData =
    dashboardData?.hiringStatus?.find((item) => item.weeklyUnfilledJobs)
      ?.weeklyUnfilledJobs || {};

  // Extract values without indexing
  const filledJobs = Object.values(filledJobsData);
  const unfilledJobs = Object.values(unfilledJobsData);

  const jobsInWeek = Object.values(dashboardData?.totalJobsByDay || {});

  const barData = {
    labels: ["Mon", "Tue", "Wed", "Thurs", "Fri", "Sat", "Sun"],
    datasets: [
      {
        label: "Filled Jobs",
        backgroundColor: "#20117a",
        // data: [13, 22, 22, 13, 0, 22, 0],
        data: filledJobs,
      },
      {
        label: "Unfilled Jobs",
        backgroundColor: "#b0b0b0",
        data: unfilledJobs,
      },
    ],
  };

  const lineData = {
    labels: ["Mon", "Tue", "Wed", "Thurs", "Fri", "Sat", "Sun"],
    datasets: [
      {
        label: "Total Jobs",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        borderColor: "#1a1aff",
        data: jobsInWeek,
        fill: false,
      },
    ],
  };

  const formatName = (name) => {
    if (!name) return "";
    const nameParts = name.split(" ");
    if (nameParts.length > 1) {
      return `${nameParts[0][0]}.${nameParts[1]}`;
    }
    return name;
  };

  const truncateString = (text, maxLength) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  const toggleData = [
    { label: "No Shows", key: "no_shows" },
    { label: "Posted", key: "posted" },
    { label: "Ongoing", key: "ongoing" },
    { label: "Cancel by Worker", key: "cancelled_by_worker" },
    { label: "Cancel by Employer", key: "cancelled_by_employer" },
    { label: "Hired", key: "hired" },
    { label: "Complete", key: "completed" },
  ];

  const jobStatusLabels=[
    // { label: "No Shows", color: "#20127a" },
    { label: "Posted", color: "#67088a" },
    { label: "Hired", color: "#00b094" },
    { label: "Complete", color: "#ffe711" },
    { label: "Ongoing", color: "#817828" },
    { label: "Cancel by Worker", color: "#ff0000" },
    { label: "Cancel by Employer", color: "#000000" },
  ]

  const handleToggleChange = (key, checked) => {
    setSelectedKeys((prevKeys) =>
      checked ? [...prevKeys, key] : prevKeys.filter((k) => k !== key)
    );
  };

  useEffect(() => {
    setTimeout(() => {
      if (isError && message) {
        toast.error(message);
      }
    }, 300);
  }, [isError, message]);

  const data = {
    start_date: start_date,
    end_date: end_date,
    start_date_day: start_date_day,
    end_date_day: end_date_day,
  };

  useEffect(() => {
    dispatch(adminDashboard(data));
  }, [
    isError,
    message,
    startOfWeek && endOfWeek,
    jobStartWeekStart && jobStartWeekEnd,
  ]);

  useEffect(() => {
    if (year || selectedKeys?.length > 0) {
      dispatch(jobStatusChart({ year: year, status: selectedKeys }));
    }
  }, [year, selectedKeys?.length]);

  if (isLoading && !startOfWeek && !jobStartWeekStart ) return <Spinner />;
  return (
    <Fragment>
      <Nav />
      <div className="content-body">
        <div className="container-fluid">
          <h1 className="text-black">Welcome</h1>
          <h1 className="text-success text-capitalize">
            {user?.name || "Admin"}
          </h1>
          <div className="col-xl-12 my-5">
            {/* <div className="d-sm-flex align-items-center mb-3 mt-sm-0 mt-2">
                <h4 className="text-black fs-20 mr-auto">Featured Companies</h4>
                <Link
                  to="/employers"
                  className="btn btn-primary light btn-rounded"
                >
                  View More
                  <svg
                    className="ml-3"
                    width="24"
                    height="14"
                    viewBox="0 0 24 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.5607 5.93941L18.2461 0.62482C17.9532 0.331898 17.5693 0.185461 17.1854 0.185461C16.8015 0.185461 16.4176 0.331898 16.1247 0.62482C15.539 1.21062 15.539 2.16035 16.1247 2.74615L18.8787 5.50005L1.5 5.50005C0.671578 5.50005 0 6.17163 0 7.00005C0 7.82848 0.671578 8.50005 1.5 8.50005L18.8787 8.50005L16.1247 11.254C15.539 11.8398 15.539 12.7895 16.1247 13.3753C16.7106 13.9611 17.6602 13.9611 18.2461 13.3753L23.5607 8.06069C24.1464 7.47495 24.1464 6.52516 23.5607 5.93941Z"
                      fill="#40189D"
                    />
                  </svg>
                </Link>
              </div> */}
            <FeaturedSlide dashboardData={dashboardData} />
          </div>
          <div className="row">
            <Container fluid>
              <Row>
                <Col lg={6}>
                  <Card>
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <Card.Title>
                            <b>This Week</b>
                          </Card.Title>
                          <Card.Subtitle>
                            <h1 className="mb-2 text-success my-3">
                              Hiring Status
                            </h1>
                          </Card.Subtitle>
                        </div>
                        <Form className="d-flex">
                          <div className="container d-flex flex-column align-items-center justify-content-center gap-3">
                            {/* DatePicker Input */}
                            <div
                              className="w-100"
                              style={{ maxWidth: "320px" }}
                            >
                              <ReactDatePicker
                                selected={selectedWeek}
                                onChange={handleWeekChange}
                                dateFormat="yyyy-MM-dd"
                                showWeekNumbers
                                showWeekPicker
                                isClearable
                                placeholderText="Select a week"
                                className="form-control text-center border-dark rounded-3"
                              />
                            </div>

                            {/* Display Start and End of the Week in a Smaller Box */}
                            {startOfWeek && endOfWeek && (
                              <div
                                className="border rounded p-2 text-center small w-100 mt-2"
                                style={{
                                  maxWidth: "320px",
                                  fontSize: "12px",
                                  lineHeight: "1.2",
                                  padding: "5px",
                                }}
                              >
                                <strong>Start:</strong>{" "}
                                {startOfWeek.toDateString()} <br />
                                <strong>End:</strong> {endOfWeek.toDateString()}
                              </div>
                            )}
                          </div>
                        </Form>
                      </div>
                      <Bar data={barData} />
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card>
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <Card.Title>
                            <b>This Week</b>
                          </Card.Title>
                          <Card.Subtitle>
                            <h1 className="mb-2 text-success my-3">
                              Job Starting
                            </h1>
                          </Card.Subtitle>
                        </div>
                        <div>
                          <Form className="d-flex justify-content-end">
                            <div className="container d-flex flex-column align-items-center justify-content-center gap-3">
                              <div
                                className="w-100"
                                style={{ maxWidth: "320px" }}
                              >
                                <ReactDatePicker
                                  selected={selectedJobStartWeek}
                                  onChange={handleJobStartWeekChange}
                                  dateFormat="yyyy-MM-dd"
                                  showWeekNumbers
                                  showWeekPicker
                                  isClearable
                                  placeholderText="Select Week"
                                  className="form-control text-center border-dark rounded-3"
                                />
                                {jobStartWeekStart && jobStartWeekEnd && (
                                  <div
                                    className="border rounded p-2 text-center small mt-2"
                                    style={{
                                      maxWidth: "320px",
                                      fontSize: "12px",
                                      lineHeight: "1.2",
                                      padding: "5px",
                                    }}
                                  >
                                    <strong>Start:</strong>{" "}
                                    {jobStartWeekStart.toDateString()} <br />
                                    <strong>End:</strong>{" "}
                                    {jobStartWeekEnd.toDateString()}
                                  </div>
                                )}
                              </div>
                            </div>
                          </Form>
                          {/* <Form inline>
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              customInput={
                                <Button variant="outline-primary">
                                  Select Date
                                </Button>
                              }
                            />
                          </Form> */}
                        </div>
                      </div>
                      <Line data={lineData} />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>

            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 col-sm-11">
                  <div className="card wh-50">
                    <div className="card-header d-flex flex-wrap justify-content-between align-items-center">
                      <h4 className="fs-20 text-black mr-3 mb-3 mb-md-0">
                        <b>Job Stats</b>
                      </h4>
                      <div className="d-flex flex-wrap align-items-center">
                        {toggleData?.map(({ label, key }, index) => (
                          <div
                            key={index}
                            className="custom-control custom-switch toggle-switch text-right mr-3 mb-2"
                          >
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={`customSwitch${index + 1}`}
                              onChange={(e) =>
                                handleToggleChange(key, e.target.checked)
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`customSwitch${index + 1}`}
                            >
                              {label}
                            </label>
                          </div>
                        ))}
                        <Form className="d-flex align-items-center ml-3 mb-2">
                          <ReactDatePicker
                            selected={selectYear}
                            onChange={(date) => handleYearChange(date)}
                            className="form-control rounded-3 border-dark bg-transparent mx-4 date-picker-custom"
                            dateFormat="yyyy" // Show only the year
                            showYearPicker // Enable year selection only
                            yearDropdownItemNumber={10} // Number of years in dropdown
                            scrollableYearDropdown // Enables scroll for years
                            placeholderText="Select Year"
                          />

                          {/* <i class="fa-solid fa-chevron-down"></i> */}
                        </Form>
                      </div>
                    </div>
                    <div className="card-body">
                      {/* DualLine3 is a two line chart */}
                      <>
                        <div style={{ height: "520px", width: "200%" }}>
                          {/* <Line data={data} options={options} /> */}
                          <DualLine3 chartData={jobStatusData} />
                        </div>
                      </>
                      <div className="d-flex flex-wrap align-items-center justify-content-center mt-3">
                        {jobStatusLabels?.map((item, index) => (
                          <div
                            key={index}
                            className="fs-14 text-black mr-4 mb-2 d-flex align-items-center"
                          >
                            <svg
                              className="mr-2"
                              width="19"
                              height="19"
                              viewBox="0 0 19 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="19"
                                height="19"
                                rx="9.5"
                                fill={item.color}
                              />
                            </svg>
                            {item.label}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Uncomment the following if needed */}
                {/* <div className="col-12">
                  <h4 className="fs-20 text-black mb-sm-4 mt-sm-0 mt-2 mb-2">
                    Featured Jobs
                  </h4>
                  <JobSlide />
                </div> */}
              </div>
            </div>
            <h1 className="my-5">
              <b>Top 10 Rated Workers</b>
            </h1>
            <div className="row">
              <div className="col-xl-12">
                <div className="table-responsive">
                  <div
                    id="example5_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      className="table display mb-4 dataTablesCard card-table dataTable no-footer"
                      id="example5"
                      role="grid"
                      aria-describedby="example5_info"
                    >
                      <thead>
                        <tr role="row">
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="ID: activate to sort column ascending"
                          >
                            Id.
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Company: activate to sort column ascending"
                            style={{ paddingLeft: "20px" }}
                          >
                            Image
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Type: activate to sort column ascending"
                          >
                            Name
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                          >
                            Employer
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                          >
                            Joined Date
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Postition: activate to sort column ascending"
                            style={{ paddingLeft: "26px", width: 180 }}
                          >
                            Status
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                            style={{ paddingLeft: "23px" }}
                          >
                            Approval
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="example5"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Status: activate to sort column ascending"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashboardData?.top10RatedWorkers?.map(
                          (item, index) => (
                            <tr
                              key={item._id}
                              role="row"
                              className={index % 2 === 0 ? "even" : "odd"}
                            >
                              <td>{index + 1}.</td>
                              <td>
                                <img
                                  src={item.image} // Assuming it's a URL or needs to be prefixed with a base URL
                                  alt={item.name}
                                  width="50"
                                  height="50"
                                  className="rounded-circle"
                                />
                              </td>
                              <td>
                                <div className="media">
                                  {/* <div className="media-body text-nowrap">
                                    <h6 className="fs-16 mb-0">{item.name}</h6>
                                  </div> */}
                                  {formatName(item?.name)?.length > 10 ? (
                                    <>
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip>
                                            {formatName(item?.name)?.length > 10
                                              ? formatName(item?.name)
                                              : ""}
                                          </Tooltip>
                                        }
                                      >
                                        <h6
                                          key={index} // Ensure unique key if rendering in a list
                                          className="fs-16 mb-0 text-capitalize"
                                        >
                                          {truncateString(
                                            formatName(item?.name),
                                            10
                                          )}
                                        </h6>
                                      </OverlayTrigger>
                                    </>
                                  ) : (
                                    <>
                                      <h6
                                        key={index} // Ensure unique key if rendering in a list
                                        className="fs-16 mb-0 text-capitalize"
                                      >
                                        {truncateString(
                                          formatName(item?.name),
                                          10
                                        )}
                                      </h6>
                                    </>
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="media">
                                  <div className="media-body text-nowrap">
                                    <h6 className="textfs-16 mb-0">
                                      {item.email}
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="media">
                                  <div className="media-body text-nowrap">
                                    <h6 className="textfs-16 mb-0">
                                      {getDate(item.createdAt)}
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center text-center">
                                  {item.status === true ? (
                                    <button className="btn btn-rounded btn-sm btn-light border-0">
                                      <span
                                        onClick={() => {
                                          dispatch(
                                            toggleStatus({
                                              user_id: item._id,
                                              pageno: 1,
                                            })
                                          );
                                        }}
                                        className="text-success"
                                      >
                                        Active
                                      </span>
                                    </button>
                                  ) : (
                                    <button className="btn btn-rounded btn-sm btn-light border-0">
                                      <span
                                        onClick={() => {
                                          dispatch(
                                            toggleStatus({
                                              user_id: item._id,
                                              pageno: 1,
                                            })
                                          );
                                        }}
                                        className="text-danger"
                                      >
                                        Inactive
                                      </span>
                                    </button>
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center text-center">
                                  {(() => {
                                    if (item.is_approved === "0") {
                                      return (
                                        <button className="btn btn-rounded btn-light btn-sm">
                                          <span
                                            onClick={() => {
                                              dispatch(
                                                toggleApproved({
                                                  user_id: item._id,
                                                  pageno: 1,
                                                })
                                              );
                                            }}
                                            style={{ color: "orange" }}
                                          >
                                            Pending
                                          </span>
                                        </button>
                                      );
                                    } else if (item.is_approved === "1") {
                                      return (
                                        <button className="btn btn-rounded btn-sm btn-light">
                                          <span
                                            onClick={() => {
                                              dispatch(
                                                toggleApproved({
                                                  user_id: item._id,
                                                  pageno: 1,
                                                })
                                              );
                                            }}
                                            className="text-success"
                                          >
                                            Approved
                                          </span>
                                        </button>
                                      );
                                    } else {
                                      return (
                                        <button className="btn btn-rounded btn-light btn-sm">
                                          <span
                                            onClick={() => {
                                              dispatch(
                                                toggleApproved({
                                                  user_id: item._id,
                                                  pageno: 1,
                                                })
                                              );
                                            }}
                                            className="text-danger"
                                          >
                                            Declined
                                          </span>
                                        </button>
                                      );
                                    }
                                  })()}
                                </div>
                              </td>
                              <td style={{ paddingLeft: "25px" }}>
                                <Link to={"/admin/worker/Profile/" + item?._id}>
                                  <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 38 40"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M18.5728 23.3332C20.2823 23.3332 21.668 21.8408 21.668 19.9998C21.668 18.1589 20.2823 16.6665 18.5728 16.6665C16.8633 16.6665 15.4776 18.1589 15.4776 19.9998C15.4776 21.8408 16.8633 23.3332 18.5728 23.3332Z"
                                      fill="#00B094"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M2.32893 19.5004C4.49779 12.0639 10.9481 6.6665 18.5731 6.6665C26.1981 6.6665 32.6485 12.0639 34.8173 19.5005C34.9121 19.8256 34.9121 20.1742 34.8173 20.4993C32.6484 27.9358 26.1981 33.3332 18.5731 33.3332C10.9481 33.3332 4.49775 27.9357 2.32892 20.4992C2.23411 20.1741 2.23411 19.8255 2.32893 19.5004ZM12.3823 19.9998C12.3823 16.3179 15.1539 13.3332 18.5728 13.3332C21.9917 13.3332 24.7633 16.3179 24.7633 19.9998C24.7633 23.6817 21.9917 26.6665 18.5728 26.6665C15.1539 26.6665 12.3823 23.6817 12.3823 19.9998Z"
                                      fill="#00B094"
                                    />
                                  </svg>
                                </Link>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
