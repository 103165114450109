import React from 'react';
import { Dropdown, ButtonGroup } from 'react-bootstrap';

const StatusDropdown = ({ event, handleToggleStatus, selectedStatus }) => {
    return (
        <>
        {
            console.log(event,'event')
            // console.log(handleToggleStatus,'handleToggleStatus')
            // console.log(selectedStatus,'selectedStatus')
        }
        <div className="d-flex align-items-center gap-2">
            {/* Status Button */}

            {/* Dropdown Menu */}
            <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle variant="" data-toggle="dropdown border-light">
                    <svg width="30" height="32" viewBox="0 0 38 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4766 9.99984C15.4766 8.15889 16.8623 6.6665 18.5718 6.6665C20.2813 6.6665 21.667 8.15889 21.667 9.99984C21.667 11.8408 20.2813 13.3332 18.5718 13.3332C16.8623 13.3332 15.4766 11.8408 15.4766 9.99984ZM15.4766 19.9998C15.4766 18.1589 16.8623 16.6665 18.5718 16.6665C20.2813 16.6665 21.667 18.1589 21.667 19.9998C21.667 21.8408 20.2813 23.3332 18.5718 23.3332C16.8623 23.3332 15.4766 21.8408 15.4766 19.9998ZM15.4766 29.9998C15.4766 28.1589 16.8623 26.6665 18.5718 26.6665C20.2813 26.6665 21.667 28.1589 21.667 29.9998C21.667 31.8408 20.2813 33.3332 18.5718 33.3332C16.8623 33.3332 15.4766 31.8408 15.4766 29.9998Z" fill="#00B094" />
                    </svg>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleToggleStatus(event._id, 'onboarded' ,event.worker_id)}>
                        Onboard
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => handleToggleStatus(event._id, 'cancelled' , event.worker_id)}
                        className="text-danger"
                    >
                        Cancel
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
        </>
    );
};

export default StatusDropdown;
