import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASEURL

// create Onboarding Admin
const createOnboardingAdmin = async (token, userData) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        const response = await axios.post(`${BASE_URL}/admin/onboarding_staff`, userData, config);

        return response.data;
    } catch (error) {
        console.log(error)
    }
}


// get All Onboarding Admin
const getOnboardingAdmins = async (token, data) => {

    let pageno = data?.pageno || 1;
    let url = `${BASE_URL}/admin/onboarding_staff?pageno=${pageno}`;
    if(data?.status){
        if(data?.status !== 'all'){
            url = `${BASE_URL}/admin/onboarding_staff?pageno=${pageno}&status=${data?.status}`;
        }
    }
    if(data?.search){
       url += `&search=${data?.search}`
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(url, config);
    if (response.data.body) {
        return response.data;
    }
}

// toggle Admin Status
const toggleAdminStatus = async (token, userId) => {
    const onboarding_staff_id = userId;

    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.put(`${BASE_URL}/admin/onboarding_staff/status/${onboarding_staff_id}`, {}, config);
    if (response.data.body) {
        return response.data;
    }
}

// Change password
const changePassword = async (token, userData) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.put(`${BASE_URL}/admin/onboarding_staff/password`, userData, config);
    if (response.data.body) {
        return response.data;
    }
}

const onboardingSlots = async (token, date) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(`${BASE_URL}/admin/onboarding_staff/appointments?date=${date}`, config);
    if (response.data.body) {
        return response.data;
    }
}

const updateAppointmentStatus = async (token, appointment) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.put(`${BASE_URL}/admin/onboarding_staff/appointment_status/${appointment?.onboarding_id}`, { status: appointment?.status , user_role : appointment?.user_role }, config);
    if (response.data.body) {
        return response.data;
    }
}




const onboardingAdminService = {
    createOnboardingAdmin, getOnboardingAdmins, toggleAdminStatus, changePassword, onboardingSlots, updateAppointmentStatus
}


export default onboardingAdminService;