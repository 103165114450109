import axios from 'axios';
import { format } from 'date-fns';
const BASE_URL = process.env.REACT_APP_BASEURL;

// get All Employers
const getAllEmployers = async (token, data) => {
    try {
        const page = data?.pageno || 1;
        let url = `${BASE_URL}/admin/employers?pageno=${page}`;
        if (data?.selectedStatus !== null && data?.selectedStatus !== undefined) {
          url += `&status=${data.selectedStatus}`;
        }
        if (data?.selectedApproval) {
          url += `&isApproved=${data.selectedApproval}`;
        }
        if (data?.selectedTier) {
          url += `&tier=${data.selectedTier}`;
        }
        if (data?.joiningDate) {
            const date = new Date(data?.joiningDate);
            const formattedDate = format(date, 'dd/MM/yyyy');
            url += `&joiningDate=${formattedDate}`;
          }
        if(data?.admin_input){
          url += `&admin_input=${data.admin_input}`
        }
        if(data?.search){
            url += `&search=${data.search}`
        }
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        const response = await axios.get(url, config);
        if (response?.data?.body) {
            return response.data;
        }
    } catch (error) {
        console.log("error => ", error)
        throw error
    }
}

// Get Employer Details / Profile
const getEmployersProfile = async (token, id) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    }
    const response = await axios.get(`${BASE_URL}/admin/employer/${id}`, config);
    if (response.data.body) {
        return response.data;
    }
}


const toggleStatus = async (token, pageno, user_id) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.put(`${BASE_URL}/admin/employer/status/${user_id}?pageno=${pageno}`, null, config);
    if (response.data.body) {
        return response.data;
    }
}


const toggleApproved = async (token, pageno, user_id) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.put(`${BASE_URL}/admin/employer/approved/${user_id}?pageno=${pageno}`, null, config);
    if (response.data.body) {
        return response.data;
    }
}


const toggleAdminInput = async (token, pageno, user_id) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.put(`${BASE_URL}/admin/employer/admininput/${user_id}?pageno=${pageno}`, null, config);
    if (response.data.body) {
        return response.data;
    }
}

// Get Employer Jobs //
const getEmployerJobs = async (token, data) => {
    const page = data?.pageno || 1;
    let url = `${BASE_URL}/admin/employer/${data?.id}/jobs?pageno=${page}`;
    if (data?.status) {
        url += `&status=${data.status}`;
    }
    if (data?.start_date && data?.end_date) {
      url += `&from=${data.start_date}&to=${data.end_date}`;
    }
    if(data?.keyword){
        url += `&keyword=${data.keyword}`
    }

    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    try {
        return await axios.get(url, config)
    } catch (error) {
        console.log(error)
        throw error
    }
}


const getVenues = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    try {
        return await axios.get(`${BASE_URL}/employer/venue`, config)
    } catch (error) {
        console.log(error)
        throw error
    }
}


const getAllEmployerSpend = async (token, data) => {
    try {
        const page = data?.pageno || 1;
        let url = `${BASE_URL}/admin/employerSpend?pageno=${page}`;
        if (data?.search) {
          url += `&search=${data.search}`;
        }
        if(data?.from && data?.to){
            url += `&from=${data.from}&to=${data.to}`
        }
       
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        const response = await axios.get(url, config);
        if (response?.data?.body) {
            return response.data;
        }
    } catch (error) {
        console.log("error => ", error)
        throw error
    }
}


const employerService = {
    getAllEmployers, toggleApproved, toggleStatus, toggleAdminInput, getEmployersProfile, getEmployerJobs, getVenues ,getAllEmployerSpend
}


export default employerService;