import React, { useEffect, useState } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  allAdmin,
  changePassowrd,
  reset,
} from "../../../features/admins/adminSlice";
import { toast } from "react-toastify";

const ChangePassword = ({
  handleClosePasswordModal,
  selectedAdmin,
  pageNumber,
}) => {
  const [formData, setFormData] = useState({
    password: "",
    cpassword: "",
  });

  const handleChange = (e) => {
    setFormData((previousState) => ({
      ...previousState,
      [e.target.name]: e.target.value,
    }));
  };

  const dispatch = useDispatch();

  const { ChangePassword, isError, isSuccess, message } = useSelector(
    (state) => state.onboardingAdmin
  );

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    dispatch(reset());
  }, [ChangePassword, dispatch]);

  const onSubmit = (e) => {
    e.preventDefault();
    const userData = {
      user_id: selectedAdmin._id,
      newpassword: formData.password,
      confirmpassword: formData.cpassword,
    };
    dispatch(changePassowrd(userData)).then(() => {
      dispatch(allAdmin({ pageNumber: pageNumber + 1 })).then(()=>{
        toast.success("Password Reset Successfully");
      })
    });
    handleClosePasswordModal();
  };

  return (
    <>
      <div className="rounded-3 my-4" style={{ border: "1px solid #e5e5e5" }}>
        <InputGroup className="">
          <InputGroup.Text className="bg-transparent">
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 6V5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5V6M9 11H15C15.5523 11 16 10.5523 16 10V7C16 6.44772 15.5523 6 15 6H9C8.44772 6 8 6.44772 8 7V10C8 10.5523 8.44772 11 9 11ZM5 21H19C20.1046 21 21 20.1046 21 19V16C21 14.8954 20.1046 14 19 14H5C3.89543 14 3 14.8954 3 16V19C3 20.1046 3.89543 21 5 21Z"
                stroke="#00B094"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <circle cx="7.5" cy="17.5" r="1.5" fill="#00B094" />
              <circle cx="12" cy="17.5" r="1.5" fill="#00B094" />
              <circle cx="16.5" cy="17.5" r="1.5" fill="#00B094" />
            </svg>
          </InputGroup.Text>
          <FormControl
            className="rounded-3 border-0"
            type="password"
            id="password"
            name="password"
            placeholder="Enter Password"
            onChange={handleChange}
          />
        </InputGroup>
      </div>
      <div className="rounded-3 my-4" style={{ border: "1px solid #e5e5e5" }}>
        <InputGroup className="">
          <InputGroup.Text className="bg-transparent">
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 6V5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5V6M9 11H15C15.5523 11 16 10.5523 16 10V7C16 6.44772 15.5523 6 15 6H9C8.44772 6 8 6.44772 8 7V10C8 10.5523 8.44772 11 9 11ZM5 21H19C20.1046 21 21 20.1046 21 19V16C21 14.8954 20.1046 14 19 14H5C3.89543 14 3 14.8954 3 16V19C3 20.1046 3.89543 21 5 21Z"
                stroke="#00B094"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <circle cx="7.5" cy="17.5" r="1.5" fill="#00B094" />
              <circle cx="12" cy="17.5" r="1.5" fill="#00B094" />
              <circle cx="16.5" cy="17.5" r="1.5" fill="#00B094" />
            </svg>
          </InputGroup.Text>
          <FormControl
            className="rounded-3 border-0"
            type="password"
            id="cpassword"
            name="cpassword"
            placeholder="Enter confirm password"
            onChange={handleChange}
          />
        </InputGroup>
      </div>

      <div className="text-center">
        <Button
          type="submit"
          className="btn btn-success rounded-5 mb-3 mt-3 py-3 btn-md"
          onClick={onSubmit}
        >
          Save Changes
        </Button>
      </div>
    </>
  );
};

export default ChangePassword;
